import { Box, Button, Tab } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useListChargersV2ShallowQuery } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { events } from 'src/lib/utils/analytics-events';
import { ChargerPriceGroupsList } from './ChargerPricesList';
import { PriceLog } from './PriceLog';

import useAnalytics from 'src/lib/hooks/useAnalytics';
import config from 'src/config';
import { StyledTabs } from 'src/components/shared/StyledTabs';

export const ChargerPricesListView: React.FC = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const id = 'charger-prices';
  const [activeTab, setActiveTab] = React.useState<string>('overview');
  const chargersWithoutPricingInput = {
    pageSize: config.maxPageSize,
    filter: {
      tariffId: { eq: null },
    },
  };
  const { data } = useListChargersV2ShallowQuery({
    variables: {
      input: chargersWithoutPricingInput,
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalCount = data?.listChargersV2?.total || 0;

  const handleChangeTab = (event: React.ChangeEvent<unknown>, tab: string) => {
    setActiveTab(tab);
  };

  return (
    <PageContent
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Title>Charger Prices</Title>
              <SubTitle color="textPrimary">List of charger prices applied to your charger groups</SubTitle>
            </>
          }
        />
      }
    >
      <article>
        <StyledTabs tab={activeTab} handleChangeTab={handleChangeTab}>
          <Tab
            label="Overview"
            value="overview"
            data-testid="tab-button-profile"
            onClick={() => track(events.chargerView.CLICKED_PROFILE_TAB)}
          />
          <Tab
            label="Default Price Log"
            value="priceLog"
            data-testid="tab-button-price-log"
            onClick={() => track(events.chargerView.CLICKED_SESSIONS_TAB)}
          />
        </StyledTabs>
        <div>
          {activeTab === 'overview' && (
            <Card className="table-container">
              <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Button
                    onClick={() => {
                      navigate('/extend-plus/charger-prices/create?new=true');
                      track(events.chargerPricesList.CLICKED_ADD_CHARGER_PRICES);
                    }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    data-testid={`${id}-add-prices-button`}
                  >
                    Add Prices
                  </Button>
                </Box>

                <SubTitle>You have {totalCount} chargers without prices</SubTitle>
              </Box>
              <ChargerPriceGroupsList />
            </Card>
          )}
          {activeTab === 'priceLog' && (
            <Card className="table-container">
              <PriceLog />
            </Card>
          )}
        </div>
      </article>
    </PageContent>
  );
};
