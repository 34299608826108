import { CardHeader } from '@material-ui/core';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from '@evgo/react-material-components';
import { ChargerV2, EvseV2, useListChargersV2Query } from 'src/@types';
import { ConnectorStatusIcon } from 'src/components/shared/ConnectorStatusIcon';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import styled from 'styled-components';
import { SubTitle } from 'src/components/shared/SubTitle';
import { ChargersFilter } from '../ChargersFilter';
import { useDebounceFn } from 'ahooks';
import { Card } from 'src/components/shared/Card';
import ChargerV2SyncStatus from 'src/components/shared/ChargerSyncStatus';

const StyledCard = styled(Card)`
  margin-bottom: ${theme.spacing(6)}px;
  h6 {
    margin-left: ${theme.spacing(2)}px;
  }
`;

export type Filters = {
  connectorStatusId: number[];
  siteName: string[];
  sitePostalCode: string[];
  connectorIsReservable?: boolean;
  chargerGroupName?: string[];
};

type ColumnDataProps = {
  data: ChargerV2;
};

const ChargerGroupName: React.FC<ColumnDataProps> = ({ data }) => {
  const to = `/extend-plus/charger-groups/${data?.chargerGroup?.altId}/details`;
  return data?.chargerGroup?.chargerGroupName ? (
    <TableLink to={to}>{data.chargerGroup?.chargerGroupName}</TableLink>
  ) : (
    <p>-</p>
  );
};

const ChargerName: React.FC<ColumnDataProps> = ({ data }) => {
  const to = `/extend-plus/chargers/${data?.altId}`;
  return <TableLink to={to}>{data.chargerName}</TableLink>;
};

const SiteAddress: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <p>{`${data.site?.address1} ${data.site?.locality}, ${data.site?.administrativeArea} ${data.site?.postalCode}, ${data.site?.country}`}</p>
  );
};
const ConnectorStatusIcons: React.FC<ColumnDataProps> = ({ data }) => {
  const connectorStatuses: React.ReactElement<ChargerV2>[] = [];
  (data.evses || []).forEach((evse, indexEvse) => {
    (evse?.connectors || []).forEach((connector, indexConnector) => {
      connectorStatuses.push(
        <ConnectorStatusIcon
          key={`${data?.identityKey || ''}connector-status-${indexEvse}-${indexConnector}`}
          connectorStatus={connector?.connectorStatus?.columnText || 'Unknown'}
          displayTooltip={true}
        />,
      );
    });
  });
  return <>{connectorStatuses.map((connectorStatus) => connectorStatus)}</>;
};

const columns: Column[] = [
  { key: 'chargerName', label: 'Charger Name', sortable: true, width: '15%', component: ChargerName },
  { key: 'chargerModel.modelName', sortName: 'modelName', label: 'Model Name', sortable: true, width: '20%' },
  {
    key: 'evses.connectors.chargingLevel',
    label: 'Charger Type',
    sortable: false,
    numeric: true,
    width: '10%',
    formatWithRowData: (charger) => {
      const types = charger.evses.flatMap((evse: EvseV2) =>
        evse?.connectors?.map((connector) => connector?.chargingLevel),
      );
      return types[0];
    },
  },
  {
    key: 'connectorStatusMax',
    label: 'Connector Status',
    sortable: false,
    width: '10%',
    component: ConnectorStatusIcons,
  },
  { key: 'site.siteName', sortName: 'siteName', label: 'Site Name', sortable: true, numeric: true, width: '15%' },
  {
    key: 'site.address1',
    sortName: 'siteAddress1',
    label: 'Address',
    sortable: true,
    numeric: true,
    width: '20%',
    component: SiteAddress,
  },
  {
    key: 'chargerGroup.chargerGroupName',
    sortName: 'chargerGroupName',
    label: 'Charger Group',
    sortable: true,
    numeric: true,
    align: 'center',
    width: '10%',
    component: ChargerGroupName,
  },
  { key: 'chargerSyncStatus', label: 'Charger Status', sortable: false, width: '15%', component: ChargerV2SyncStatus },
];

export const ChargersList: React.FC = () => {
  const [queryParams] = useSearchParams();
  const statusFilterParam = queryParams.get('statusFilter') ?? '';
  const [tableSortBy, setTableSortBy] = React.useState<string>('chargerName');
  const [tableSortDirection, setTableSortDirection] = React.useState<Direction>(Direction.Asc);
  const [tableSearch, setTableSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilters] = useState<Filters>({
    connectorStatusId: Number(statusFilterParam) ? [Number(statusFilterParam)] : [],
    siteName: [],
    sitePostalCode: [],
    connectorIsReservable: undefined,
    chargerGroupName: [],
  });
  const { chargerGroupName } = filter;
  const isUnassignedIncluded = chargerGroupName?.includes('Unassigned');
  const filteredNames = chargerGroupName?.filter((name) => name !== 'Unassigned');

  const input = {
    pageSize,
    page,
    search: {
      chargerName: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      modelName: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      siteName: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      siteAddress1: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      siteLocality: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      siteAdministrativeArea: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      sitePostalCode: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
    },
    filter: {
      sitePostalCode: filter.sitePostalCode?.length === 0 ? undefined : { in: filter.sitePostalCode },
      siteName: filter.siteName?.length === 0 ? undefined : { in: filter.siteName },
      connectorIsReservable:
        filter.connectorIsReservable != undefined ? { eq: filter.connectorIsReservable } : undefined,
      connectorStatusId: filter.connectorStatusId?.length === 0 ? undefined : { in: filter.connectorStatusId },
      chargerGroupName: !chargerGroupName?.length
        ? undefined
        : isUnassignedIncluded
        ? { eq: null }
        : { in: filteredNames },
    },
    sort: tableSortBy ? { [tableSortBy]: tableSortDirection } : undefined,
  };

  const { data, loading, error } = useListChargersV2Query({
    variables: {
      input,
    },
    pollInterval: 30000,
  });

  const { edges } = data?.listChargersV2 || { edges: [] };

  const { run: debouncedOnChange } = useDebounceFn(setTableSearch, { wait: 1000 });

  return (
    <article>
      <StyledCard>
        <SubTitle>Total # of chargers: {data?.listChargersV2?.total}</SubTitle>
        <ChargersFilter
          filters={filter}
          search={tableSearch}
          onSearch={(value: string) => {
            if (value.length === 0 || value.length >= 2) {
              debouncedOnChange(value);
            }
          }}
          onFilter={(type) => setFilters(type)}
        />
      </StyledCard>
      <Card className="table-container">
        <CardHeader title="Charger List" />
        <Table
          id="chargers-table"
          data-testid="chargers-table"
          columns={columns}
          data={edges || []}
          loading={loading}
          error={error}
          noDataMessage="No matching chargers were found"
          pagination={{
            total: data?.listChargersV2?.total || 0,
            page: page || 0,
            pageSize: pageSize || 0,
            rowsPerPageOptions: [10, 25, 100],
            onPageChange: (p: number) => setPage(p),
            onPageSizeChange: (pS: number) => setPageSize(pS),
          }}
          sorting={{
            sortBy: tableSortBy,
            sortDirection: tableSortDirection,
            onSortByChange: setTableSortBy,
            onSortDirectionChange: setTableSortDirection,
          }}
        />
      </Card>
    </article>
  );
};
