import { getStoryblokApi, StoryData } from '@storyblok/react';
import { Fragment, useEffect, useState } from 'react';
import { CircularProgress, Typography, Box, Divider, Grid, IconButton, Button } from '@material-ui/core';
import { DescriptionOutlined as DescriptionIcon } from '@material-ui/icons';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';
import { events } from 'src/lib/utils/analytics-events';
import { theme } from '@evgo/react-material-components';
import { Link, useNavigate } from 'react-router-dom';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';
import { Card } from 'src/components/shared/Card';
import { ArticleStoryblok, CategoryStoryblok } from 'src/@types/storyblok';
import { extendArticlesCdn, extendCategoriesCdn, version } from 'src/storyblok';
import { groupArticlesByCategory } from './helpers';

const { spacing } = theme;

const testid = (value: string) => ({ 'data-testid': `help-${value}` });

export type Category = Omit<StoryData, 'content'> & {
  content: CategoryStoryblok;
};

export type Article = Omit<StoryData, 'content'> & {
  content: ArticleStoryblok;
  categorySlug: string;
};

export type ArticlesByCategory = {
  [key: string]: Article[];
};

const ArticleListItemDiv = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: ${spacing(2.5)}px;
`;
const TitleBox = styled(Box)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const ContactSupportContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${spacing(3)}%;
  a {
    margin-bottom: ${spacing(2)}px;
  }
`;

export const ArticleListItem = (article: Article) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { title, subtitle } = article.content;
  return (
    <ArticleListItemDiv
      {...testid('article')}
      onClick={() => {
        track(events.helpAndSupport.CLICKED_HELP_SUPPORT_ARTICLE_LINK);
        navigate(`/extend-plus/help/${article.slug}`, { state: article });
      }}
    >
      <IconButton>
        <DescriptionIcon fontSize="large" />
      </IconButton>
      <TitleBox>
        <Typography {...testid('article-title')} variant="h6">
          {title}
        </Typography>
        <Typography {...testid('article-subtitle')} variant="body1">
          {subtitle}
        </Typography>
      </TitleBox>
    </ArticleListItemDiv>
  );
};

export const ArticleList = ({
  title,
  categorySlug,
  articles,
  titleLink,
}: {
  title: string;
  categorySlug?: string;
  articles: Article[];
  titleLink?: boolean;
}) => {
  return (
    <Fragment>
      {titleLink ? (
        <Link to={`/extend-plus/help/categories/${categorySlug}`}>
          <TitleBox>
            <Typography {...testid('category-title')} variant="h5" style={{ marginBottom: `${spacing(3)}px` }}>
              {title}
            </Typography>
          </TitleBox>
        </Link>
      ) : (
        <Typography {...testid('category-title')} variant="h5" style={{ marginBottom: `${spacing(3)}px` }}>
          {title}
        </Typography>
      )}

      {articles.map((article) => {
        return <ArticleListItem {...article} key={article.uuid} />;
      })}
    </Fragment>
  );
};

export function HelpView() {
  const { track } = useAnalytics();
  const [articlesByCategory, setArticlesByCategory] = useState<ArticlesByCategory>({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    const getArticlesByCategory = async () => {
      setIsLoading(true);
      const storyblokApi = getStoryblokApi();
      try {
        const { data: articles }: { data: { stories: Article[] } } = await storyblokApi.get(extendArticlesCdn, {
          version,
        });
        const { data: categories }: { data: { stories: Category[] } } = await storyblokApi.get(extendCategoriesCdn, {
          version,
        });
        const data = groupArticlesByCategory(categories, articles);
        setArticlesByCategory(data);
      } catch (ex) {
        console.error(ex);
        setHasError(true);
      }
      setIsLoading(false);
    };
    getArticlesByCategory();
  }, []);

  if (hasError) {
    return <Fragment>Something went wrong.</Fragment>;
  }
  if (isLoading) {
    return (
      <Box {...testid('loading')} m="auto">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <PageContent pageHeader={<PageHeader childrenLeft={<Title>Help Center</Title>} />}>
      <Box>
        <Card>
          <Grid container {...testid('category-article-list')} style={{ columnGap: '120px' }}>
            {Object.keys(articlesByCategory)
              .reverse()
              .map((category, i) => {
                const articles = articlesByCategory[category];
                const categorySlug = articlesByCategory[category][0].categorySlug;
                const isEndOfRow = (i + 1) % 2 == 0 || i + 1 === Object.keys(articlesByCategory).length;
                return (
                  <Fragment {...testid('category')} key={`category-${i}`}>
                    <Grid item xs={6} style={{ flexBasis: 'calc(50% - 60px)' }}>
                      <ArticleList title={category} articles={articles} titleLink={true} categorySlug={categorySlug} />
                    </Grid>
                    {isEndOfRow && (
                      <Grid container style={{ margin: `${spacing(2)}px 0px ${spacing(5)}px 0px` }}>
                        <Grid item xs={12}>
                          <Divider></Divider>
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                );
              })}
          </Grid>
          <ContactSupportContainer>
            <Typography variant="h5" style={{ marginBottom: `${spacing(4)}px` }}>
              Customer Support
            </Typography>
            <Button
              onClick={() => {
                track(events.helpAndSupport.CLICKED_HELP_COSTUMER_SUPPORT_BUTTON);
              }}
              {...testid('customer-support')}
              variant="outlined"
              color="secondary"
              href={'https://evgoteam.atlassian.net/servicedesk/customer/portal/18'}
            >
              Report an eXtend Portal Issue
            </Button>
            <Button
              {...testid('charger-issue')}
              variant="outlined"
              color="secondary"
              href={'mailto:extendsupport@evgo.com'}
            >
              Report a Charger Issue
            </Button>
          </ContactSupportContainer>
        </Card>
      </Box>
    </PageContent>
  );
}
