export const userLocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * A helper to get the timezone abbreviation from a timezone
 * Note: Return will be user's local browser timezone if no arg is passed
 */
export const formatTimeZoneAbbreviation = (tz: string | undefined = undefined) => {
  const timeZone = tz || userLocalTimeZone;

  return new Date().toLocaleTimeString('en-us', { timeZone, timeZoneName: 'short' }).split(' ')[2];
};
