import { Box, Button, Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useListChargerGroupsForExtendQuery } from 'src/@types';
import constants from 'src/constants';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { Tooltip } from 'src/components/shared/Tooltip';
import config from 'src/config';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';
import { CustomPlanPriceGroupsList } from './CustomPlanPriceGroupsList';

export const StyledArticle = styled.article`
  .table-container > h6 {
    text-align: right;
  }
`;

export const CustomPlansListView: React.FC = () => {
  const navigate = useNavigate();
  const [showDeleted, setShowDeleted] = React.useState(false);
  const { track } = useAnalytics();
  const handleSetShowDeleted = () => {
    setShowDeleted(!showDeleted);
    track(events.customPlanList.FILTERED_DEACTIVATED_PLANS);
  };

  const { data, loading, error } = useListChargerGroupsForExtendQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
        filter: {
          chargerGroupTypeId: {
            eq: 402,
          },
        },
        showDeleted,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const edges = data?.listChargerGroups?.edges || [];
  const remainingPlans = loading ? 0 : 3 - (edges.filter((e) => !e?.deletedAt).length || 0);

  return (
    <PageContent
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Title>Custom Plan Prices</Title>
              <SubTitle color="textPrimary">List of custom plan prices applied to your chargers</SubTitle>
            </>
          }
        />
      }
    >
      <StyledArticle>
        <Card className="table-container">
          <Box pb={2} display="flex" justifyContent="space-between">
            <Button
              onClick={() => {
                navigate('/extend-plus/custom-plans/create');
                track(events.customPlanList.CLICKED_ADD_CUSTOM_PLAN_PRICES_BUTTON);
              }}
              variant="contained"
              color="secondary"
              size="large"
              data-testid="add-prices-button"
              disabled={loading || !remainingPlans}
            >
              Add custom plan prices
            </Button>
            <Box display="flex" alignItems="center">
              <SubTitle>Remaining plans available: {remainingPlans}</SubTitle>
              <Tooltip
                content={
                  <div>
                    You have a maximum of 3 active plans.
                    <br />
                    {`If you need more, contact your EVgo admin at ${constants.EXTEND_ADMIN_EMAIL}`}
                  </div>
                }
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox
              data-testid="display-deactivated-plans-checkbox"
              checked={showDeleted}
              onClick={handleSetShowDeleted}
            />
            <Typography variant="body1">display deactivated plans</Typography>
          </Box>
          <CustomPlanPriceGroupsList data={edges} loading={loading} error={error} />
        </Card>
      </StyledArticle>
    </PageContent>
  );
};
