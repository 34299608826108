import { gql } from '@apollo/client';

export const getSiteForExtend = gql`
  query getSiteForExtend($siteInput: GetSiteForExtendInput) {
    getSiteForExtend(input: $siteInput) {
      altId
      displayName
      directions
      address1
      address2
      locality
      administrativeArea
      postalCode
      timeZone
    }
  }
`;
