import moment from 'moment-timezone';
import styled from 'styled-components';
import { useReducer, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Title } from 'src/components/shared/Title';
import { PageHeader } from 'src/components/shared/PageHeader';
import { PageContent } from 'src/components/shared/PageContent';
import { MetricCardGroup } from 'src/components/shared/MetricCard';

import { TopSiteList } from './TopSiteList';
import { ReportFilter } from './ReportFilter';
import { useGetSitesForUser } from 'src/lib/hooks';
import { useGetExtendUser } from 'src/lib/hooks/useGetUserHosts';
import { SessionByWeekdayGraph } from './SessionByWeekdayGraph';
import { UnassignedChargersCard } from './UnassignedChargersCard';
import { ChargersWithoutPricingCard } from './ChargersWithoutPricingCard';
import { ConnectorStatusMetrics } from './ConnectorStatusMetrics';
import { EnergyDispensedMetrics } from './EnergyDispensedMetrics';
import { TotalSessionEnergyGraph } from './TotalSessionEnergyGraph';
import { AverageSessionByHourGraph } from './AverageSessionByHourGraph';
import { EnvironmentalStatsMetrics } from './EnvironmentalStatsMetrics';
import { DistributionByWeekDayHour } from './DistributionByWeekDayHour';
import { NetworkStatsMetrics } from './NetworkStatsMetrics';
import { LiveSessionMap } from './LiveSessionMap';
import { LiveSessions } from './LiveSessions';
import { LiveSessionMetrics } from './LiveSessionMetrics';
import { CustomPlanBreakdownChart } from './CustomPlanBreakdownChart';
import { CustomPlanEnrollments } from './CustomPlanEnrollments';
import { Host } from 'src/@types';

const StyledBox = styled(Box)`
  .right-column-wrapper {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export type Action = {
  type: 'setTimePeriod';
  timePeriod: number | unknown;
};

const initialTimePeriod = 7;
const initialTimeFrame = `${moment().tz('UTC').utc().subtract(7, 'days').format('ll')} - ${moment()
  .tz('UTC')
  .utc()
  .subtract(1, 'days')
  .format('ll')}`;

export const initialState = {
  initial: {
    timePeriod: initialTimePeriod,
    timeFrame: initialTimeFrame,
  },
  filter: {
    timePeriod: initialTimePeriod,
    timeFrame: initialTimeFrame,
  },
};

export type State = {
  initial: {
    timePeriod: Required<number>;
    timeFrame: string;
  };
  filter: {
    timePeriod: Required<number>;
    timeFrame: string;
  };
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setTimePeriod':
      const timePeriod = action.timePeriod as Required<number>;
      const isCurrentDate = timePeriod === 1;
      const timeFrame = timePeriod
        ? `${moment()
            .tz('UTC')
            .utc()
            .subtract(!isCurrentDate ? timePeriod : 0, 'days')
            .format('ll')} - ${moment()
            .tz('UTC')
            .utc()
            .subtract(!isCurrentDate ? 1 : 0, 'days')
            .format('ll')}`
        : '';

      return {
        ...state,
        filter: {
          ...state.filter,
          ...{ timePeriod, timeFrame },
        },
      };
    default:
      throw new Error();
  }
};

export function DashboardView() {
  const sites = useGetSitesForUser();
  const { host } = useGetExtendUser();
  const { customPlansEnabled } = (host as Host) || {};
  const siteIds = useMemo(() => sites.map((site) => site?.altId || ''), [sites]);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageContent pageHeader={<PageHeader childrenLeft={<Title>Dashboard</Title>} />}>
      <StyledBox>
        <ReportFilter state={state} dispatch={dispatch} />
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <LiveSessionMap siteIds={siteIds} />
            <LiveSessionMetrics timePeriod={state.filter.timePeriod} />
            <TotalSessionEnergyGraph timePeriod={state.filter.timePeriod} />
            {customPlansEnabled && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomPlanBreakdownChart timePeriod={state.filter.timePeriod} />
                </Grid>
                <Grid item xs={6}>
                  <CustomPlanEnrollments />
                </Grid>
              </Grid>
            )}
            <SessionByWeekdayGraph timePeriod={state.filter.timePeriod} timeFrame={state.filter.timeFrame} />
            <AverageSessionByHourGraph timePeriod={state.filter.timePeriod} timeFrame={state.filter.timeFrame} />
            <DistributionByWeekDayHour timePeriod={state.filter.timePeriod} />
          </Grid>
          <Grid className="right-column-wrapper" item xs={3}>
            <LiveSessions siteIds={siteIds} />
            <MetricCardGroup id="network-charger-stats">
              <NetworkStatsMetrics />
              <UnassignedChargersCard />
              <ChargersWithoutPricingCard />
              <ConnectorStatusMetrics />
            </MetricCardGroup>
            <EnergyDispensedMetrics siteIds={siteIds} />
            <TopSiteList siteIds={siteIds} />
            <EnvironmentalStatsMetrics />
          </Grid>
        </Grid>
      </StyledBox>
    </PageContent>
  );
}
