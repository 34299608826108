import { Chip } from '@material-ui/core';
import React from 'react';
import { ChargerGroup, ChargerGroupSyncStatus } from 'src/@types';
import { theme } from '@evgo/react-material-components';

const { palette, spacing } = theme;

type ChargerGroupSyncStatusProps = {
  data: ChargerGroup;
  customStyle?: Record<string, unknown>;
};

export const getStatusColor: ([]) => string = (statuses: string[]) => {
  let color = 'default';
  if (statuses.includes(ChargerGroupSyncStatus.Synced)) {
    if (statuses.includes(ChargerGroupSyncStatus.NotSynced) || statuses.includes(ChargerGroupSyncStatus.Draft)) {
      color = palette.warning.light;
    } else {
      color = palette.success.main;
    }
  } else if (statuses.includes(ChargerGroupSyncStatus.NotSynced)) {
    color = palette.error.main;
  }

  return color;
};

const customStyle = { marginBottom: `${spacing(0.5)}px` };

export default function ChargerGroupPriceType({ data }: ChargerGroupSyncStatusProps) {
  const { planTariffTags, tariff, status } = data;
  const statusColor = getStatusColor([status]);
  const chargerGroupPlans = planTariffTags?.map((tag) => tag?.planChargerGroup).filter((plan) => !!plan);
  const chips = [];

  if (chargerGroupPlans?.length) {
    const planStatuses = chargerGroupPlans.map((plan) => plan?.status as string);
    const statuses = planStatuses.reduce<string[]>((acc, cv) => {
      if (!acc.includes(cv)) acc.push(cv);
      return acc;
    }, []);
    const color = getStatusColor(statuses);
    chips.push(
      <Chip
        key={chips.length}
        variant="outlined"
        label={`Custom Plans (${chargerGroupPlans.length})`}
        style={{ color, borderColor: color, ...customStyle }}
        size="small"
      />,
    );
  }
  if (Boolean(tariff?.reservationTerm)) {
    chips.push(
      <Chip
        key={chips.length}
        variant="outlined"
        label={'Reservation Fees'}
        style={{ color: statusColor, borderColor: statusColor, ...customStyle }}
        size="small"
      />,
    );
  }
  if (Boolean(tariff)) {
    chips.push(
      <Chip
        key={chips.length}
        variant="outlined"
        label={'Charger Price'}
        style={{ color: statusColor, borderColor: statusColor, ...customStyle }}
        size="small"
      />,
    );
  }
  return <>{chips.length ? chips : 'N/A'}</>;
}
