import { Formik } from 'formik';
import React, { useState } from 'react';
import { useFormikSubmit } from 'src/lib/hooks';
import { syncUser } from '../../../../apollo/queries/accounts';
import { AccountSyncModalForm, FormValues, initialValues } from './AccountSyncModalForm';
import { getAccount as GET_ACCOUNT_QUERY } from 'src/apollo/queries/accounts';
import * as Yup from 'yup';
import { useLazyQuery } from '@apollo/client';
import { Query } from 'src/@types';
import { useParams } from 'react-router-dom';

interface Props {
  open?: boolean;
  className?: string;
}

export const AccountSyncModal: React.FC<Props> = (props) => {
  const { altId = '' } = useParams<{ altId: string }>();
  const [getAccount] = useLazyQuery<Query>(GET_ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      altId,
    },
  });
  const transformValues = (values: FormValues) => ({
    setPhoneNumber: values.setPhoneNumber,
    email: values.email,
  });
  const [shouldVerifyPhone, setShouldVerifyPhone] = useState(false);

  const handleSubmit = useFormikSubmit('AccountSync', syncUser, syncUser, {
    noRedirect: true,
    callback: () => {
      getAccount();
    },
    transformValues,
    initialValues,
  }) as never;

  const validationSchema = Yup.object({
    setPhoneNumber: Yup.string()
      .matches(/^(\d{3})(\d{3})(\d{4})$/, '* Must be a valid 10-digit phone number.')
      .required('* A phone number is required.'),
  });

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
      validationSchema={!!shouldVerifyPhone ? validationSchema : undefined}
    >
      <AccountSyncModalForm
        open={props.open}
        shouldVerifyPhone={shouldVerifyPhone}
        setShouldVerifyPhone={setShouldVerifyPhone}
      />
    </Formik>
  );
};
