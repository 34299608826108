import { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { Box } from '@material-ui/core';
import { useDebounceFn } from 'ahooks';
import { theme } from '@evgo/react-material-components';
import { Card, CardHeader } from 'src/components/shared/Card';
import ExportButton from './ExportButton';
import { SearchField } from 'src/components/shared/SearchField';
import { ListTransactionsV2TransactionItem } from 'src/@types/shared';
import { useListTransactionsV2ExtendQuery, ListTransactionsV2Input, ListTransactionsV2FilterInput } from 'src/@types';
import { Table, Column, Direction, usePagination } from 'src/components/shared/Table';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { BasicTable } from '../../../shared/BasicTable';
import { transactionColumnsByKey, transactionItemColumns } from '../../../shared/TransactionTable';
import { transactionTypes, TransactionFilter, TransactionTypeId } from '../../../shared/TransactionFilter';
import { camelToSnakeCase } from 'src/lib/helpers/camelToSnakeCase';

type Props = {
  chargerId: string;
};

const columns: Column[] = [
  transactionColumnsByKey.vendorId,
  transactionColumnsByKey.dateTime,
  transactionColumnsByKey['type.columnText'],
  transactionColumnsByKey['session.connector.connectorType.columnText'],
  transactionColumnsByKey['session.startSource.columnText'],
  transactionColumnsByKey['session.powerValue'],
  transactionColumnsByKey['session.sessionDuration'],
  transactionColumnsByKey['session.plan.chargerGroup.chargerGroupName'],
  transactionColumnsByKey.taxRate,
  transactionColumnsByKey.tax,
  transactionColumnsByKey.calculatedAmount,
];

export default function Transactions({ chargerId }: Props) {
  const pagination = usePagination();
  const { track } = useAnalytics();
  const [tableSearch, setTableSearch] = useState<string>('');
  const [tableSortBy, setTableSortBy] = useState<string>('');
  const [transactionItems, setTransactionItems] = useState<ListTransactionsV2TransactionItem[]>([]);
  const [tableSortDirection, setTableSortDirection] = useState<Direction>(Direction.Desc);

  const allTransactionTypeIds = transactionTypes.map((type) => type.id);
  const [transactionTypeIds, setTransactionTypeIds] = useState<string[]>([TransactionTypeId.Charging]);

  const filter: ListTransactionsV2FilterInput = {
    startTime: { ge: moment().subtract(5, 'years').format('YYYY-MM-DD') },
    endTime: { le: moment().add(1, 'days').format('YYYY-MM-DD') },
    chargerId: { eq: chargerId },
    transactionTypeId: {
      in: transactionTypeIds.length ? transactionTypeIds : allTransactionTypeIds,
    },
  };

  const input: ListTransactionsV2Input = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    filter,
    search: {
      vendorId: !tableSearch ? undefined : { eq: Number(tableSearch) },
    },
    // @ts-ignore it would be pretty difficult to type this. TODO: consider seperating the sortBy and direction into different fields.
    sort: tableSortBy ? `${camelToSnakeCase(tableSortBy)}_${tableSortDirection}` : 'ID_DESC',
  };

  const { data, loading, error } = useListTransactionsV2ExtendQuery({
    variables: {
      input,
    },
    pollInterval: 5000,
  });

  const { run: debouncedOnSearch } = useDebounceFn(
    (value) => {
      setTableSearch(value);
      pagination.onPageChange(0);
      track(events.reportsView.SEARCHED_SESSIONS_LIST);
    },
    { wait: 1000 },
  );

  const edges = data?.listTransactionsV2?.edges || [];
  const total = data?.listTransactionsV2?.total || 0;

  const getTransactionItemsForTransaction = useCallback(
    ({ altId }: { altId?: unknown }) => {
      const transactions = data?.listTransactionsV2?.edges || [];
      const items = transactions.find((tran) => tran?.altId === altId)?.items || [];
      setTransactionItems(items);
    },
    [data],
  );

  return (
    <Box data-testid="transactions-table-card" my={5}>
      <Card>
        <CardHeader title="Transactions" />
        <Box display="flex" justifyContent={'space-between'}>
          <Box mb={1}>
            <TransactionFilter
              value={transactionTypeIds}
              onChange={(value: string[]) => {
                track(events.reportsView.FILTER_TRANSACTION_TYPE);
                setTransactionTypeIds(value);
              }}
            />
          </Box>
          <Box display="flex" mt={3}>
            <Box>
              <SearchField
                data-testid="transactions-search"
                onChange={(value: string) => {
                  if (value.length === 0 || value.length >= 2) {
                    debouncedOnSearch(value);
                  }
                }}
                placeholder="Search ID"
              />
            </Box>
            <Box pl={2}>
              <ExportButton chargerId={chargerId} filter={filter} />
            </Box>
          </Box>
        </Box>
        <Table
          data-testid="transactions-table"
          id="transactions"
          data={edges}
          loading={loading}
          error={error}
          columns={columns}
          width={`${theme.spacing(200)}px`}
          overflow="auto"
          pagination={{ total, ...pagination }}
          sorting={{
            sortBy: tableSortBy,
            sortDirection: tableSortDirection,
            onSortByChange: setTableSortBy,
            onSortDirectionChange: setTableSortDirection,
          }}
          onCollapseClick={(row) => getTransactionItemsForTransaction({ altId: row?.altId })}
        >
          <BasicTable
            label="transactions subtable"
            className="transactions-subtable"
            columns={transactionItemColumns}
            data={transactionItems}
            loading={false}
          />
        </Table>
      </Card>
    </Box>
  );
}
