import { gql } from '@apollo/client';

export const listTransactionsV2ForExport = gql`
  query listTransactionsV2ExtendForExport($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        vendorId
        paymentTxId
        session {
          altId
          vendorId
          connector {
            connectorType {
              columnText
            }
            evse {
              charger {
                chargerName
                site {
                  siteName
                  timeZone
                }
              }
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          startTimeLocal
          endTimeLocal
          meterStart
          meterEnd
          cost
        }
        altId
        tax
        amount
        calculatedAmount
        calculatedTaxAmount
        isCalculated
        type {
          columnText
        }
      }
    }
  }
`;
