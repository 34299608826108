import { DateRangeValue } from 'src/components/shared/DateRangeField';
import { subDays, startOfDay } from 'date-fns';

export const initialDateRange = {
  startDate: startOfDay(subDays(new Date(), 30)),
  endDate: new Date(),
};

type EventLogTariffs = { id: string; tariffName: string }[];
type EventTypes = { eventName: string; value: string }[];
type Usernames = { username: string }[];

export type State = {
  initial: {
    tariffs: EventLogTariffs;
    eventTypes: EventTypes;
    usernames: Usernames;
    dateRange: Required<DateRangeValue>;
  };
  filter: {
    entityIds: string[];
    eventTypes: string[];
    usernames: string[];
    dateRange: Required<DateRangeValue>;
  };
};

export const initialState: State = {
  initial: {
    tariffs: [],
    eventTypes: [],
    usernames: [],
    dateRange: initialDateRange,
  },
  filter: {
    entityIds: [],
    eventTypes: [],
    usernames: [],
    dateRange: initialDateRange,
  },
};

type SetInitialChargerPriceGroups = { type: 'setInitialChargerPriceGroups'; tariffs: EventLogTariffs };
type SetDateRange = { type: 'setDateRange'; dateRange: DateRangeValue };
type SetInitialEventTypes = { type: 'setInitialEventTypes'; eventTypes: EventTypes };
type SetInitialUsers = { type: 'setInitialUsers'; usernames: Usernames };
type SetChargerPriceGroups = { type: 'setChargerPriceGroups'; entityIds: string[] };
type SetEventTypes = { type: 'setEventTypes'; eventTypes: string[] };
type SetUsers = { type: 'setUsers'; usernames: string[] };
type ResetFilter = { type: 'resetFilter' };

export type Action =
  | SetInitialChargerPriceGroups
  | SetDateRange
  | SetInitialEventTypes
  | SetInitialUsers
  | SetChargerPriceGroups
  | SetEventTypes
  | SetUsers
  | ResetFilter;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setInitialChargerPriceGroups':
      return {
        ...state,
        initial: {
          ...state.initial,
          tariffs: action.tariffs,
        },
      };
    case 'setInitialEventTypes':
      return {
        ...state,
        initial: {
          ...state.initial,
          eventTypes: action.eventTypes,
        },
      };
    case 'setInitialUsers':
      return {
        ...state,
        initial: {
          ...state.initial,
          usernames: action.usernames,
        },
      };
    case 'setChargerPriceGroups':
      return {
        ...state,
        filter: {
          ...state.filter,
          entityIds: action.entityIds,
        },
      };
    case 'setEventTypes':
      return {
        ...state,
        filter: {
          ...state.filter,
          eventTypes: action.eventTypes,
        },
      };
    case 'setUsers':
      return {
        ...state,
        filter: {
          ...state.filter,
          usernames: action.usernames,
        },
      };
    case 'setDateRange':
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: action.dateRange as Required<DateRangeValue>,
        },
      };
    case 'resetFilter':
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      };
    default:
      throw new Error();
  }
};
