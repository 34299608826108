import { useQuery } from '@apollo/client';
import { Divider, Dropdown, TextInfo } from '@evgo/react-material-components';
import { Checkbox, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { listFalconConstraints } from 'src/apollo/queries/options';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBlurDropdown } from 'src/lib/hooks';
import { SitePhotos } from '../SitePhotos';
import { Styled as StyledAccordion } from './styles';
import { CustomHoursInput } from 'src/components/shared/CustomPricingInput/CustomHoursInput';
import { Query } from 'src/@types';

export interface Props {
  className?: string;
}

interface Values {
  target: string;
  locationTypeId: string;
  siteAccessId: string;
  hoursOfOperation: {
    custom?: boolean;
    from?: string;
    to?: string;
  };
}

const siteMarketOptions = [
  { value: 'LOCATION_MARKET_1', label: 'Location Market 1' },
  { value: 'LOCATION_MARKET_2', label: 'Location Market 2' },
  { value: 'LOCATION_MARKET_3', label: 'Location Market 3' },
  { value: 'LOCATION_MARKET_4', label: 'Location Market 4' },
  { value: 'LOCATION_MARKET_5', label: 'Location Market 5' },
];

/**
 * Site Location component
 */
export const SiteLocation: React.FC<Props> = (props) => {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue } = useFormikContext<Values>();

  const { altId } = useParams<{ altId: string }>();
  const id = _.kebabCase('SiteLocation');
  const className = id;

  const [locationPanelOpen, setLocationPanelOpen] = useState(true);
  let customClass = className;
  if (props.className) customClass += ` ${props.className}`;

  const { data: siteTypes } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'location_type',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });
  const siteTypeOptions = (siteTypes?.listFalconConstraints?.edges || []).map((option) => ({
    label: option?.columnText,
    value: Number(option?.id),
  }));

  const { data: accessTypes } = useQuery<Query>(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: {
            eq: 'sites',
          },
          columnName: {
            eq: 'access',
          },
        },
        sort: {
          orderBy: 'ASC',
        },
      },
    },
  });
  const accessOptions = (accessTypes?.listFalconConstraints?.edges || []).map((option) => ({
    label: option?.columnText,
    value: Number(option?.id),
  }));

  // Handles changing time
  const handleChangeTime = useCallback(
    (event) => {
      const {
        target: { name, value },
      } = event;

      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleBlurDropdown = useBlurDropdown();

  return (
    <StyledAccordion
      id={`${id}-expansion-panel`}
      className={customClass}
      expanded={locationPanelOpen}
      onChange={() => setLocationPanelOpen(!locationPanelOpen)}
    >
      <AccordionSummary className={className} expandIcon={!!altId && <ExpandMore />} component="header">
        <Typography className={className} variant="h6" component="h2" id={`${id}-header`}>
          Location Information
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails className={`${className} panel-details`}>
        <Fragment>
          <div className={className}>
            <div className={`${className} hours`}>
              <div className={className}>
                <TextInfo
                  id={`${id}-time-zone`}
                  className={`${className}`}
                  label="Time Zone"
                  labelProps={{ className, shrink: true }}
                  name="timeZone"
                  disabled={true}
                  value={_.get(values, 'timeZone')}
                />
                <div className={`${className} hours-wrapper`}>
                  <Typography className={`${className} hours-label`} variant="subtitle1" component="h3">
                    Hours
                  </Typography>
                  <TextInfo
                    className={className}
                    disabled={_.get(values, 'hoursOfOperation.custom')}
                    error={!!_.get(errors, 'hoursOfOperation.from')}
                    helpertext={_.get(errors, 'hoursOfOperation.from')}
                    id={`${id}-hour-from-time-input`}
                    inputProps={{ className }}
                    label="From"
                    labelProps={{ className, shrink: true }}
                    name="hoursOfOperation.from"
                    onBlur={handleBlur}
                    onChange={handleChangeTime}
                    type="time"
                    value={_.get(values, 'hoursOfOperation.from')}
                  />

                  <TextInfo
                    className={className}
                    disabled={_.get(values, 'hoursOfOperation.custom')}
                    error={!!_.get(errors, 'hoursOfOperation.to')}
                    helpertext={_.get(errors, 'hoursOfOperation.to')}
                    id={`${id}-hour-to-time-input`}
                    inputProps={{ className }}
                    label="To"
                    labelProps={{ className, shrink: true }}
                    name="hoursOfOperation.to"
                    onBlur={handleBlur}
                    onChange={handleChangeTime}
                    type="time"
                    value={_.get(values, 'hoursOfOperation.to')}
                  />
                </div>
              </div>

              <Divider className={className} />

              <div className={`${className} checkbox-container`}>
                <Checkbox
                  id={`${id}-custom-operation-hours-checkbox`}
                  className={className}
                  checked={_.get(values, 'hoursOfOperation.custom', false)}
                  value={_.toString(_.get(values, 'hoursOfOperation.custom', false))}
                  onChange={handleChange}
                  name="hoursOfOperation.custom"
                  inputProps={{ id: `${id}-customize-hours-checkbox` }}
                />
                <Typography className={className} variant="subtitle1" component="h3">
                  Customize Hours
                </Typography>
              </div>

              {_.get(values, 'hoursOfOperation.custom', false) && (
                <div className={`${className} custom-hours-container`}>
                  <CustomHoursInput name="customHours" id="site-location-custom-hours" />
                </div>
              )}
            </div>

            <Divider vertical />

            <div className={className}>
              <Typography className={className} variant="subtitle1" component="h3">
                Access
              </Typography>

              <Dropdown
                id={`${id}-access`}
                className={`${className} half`}
                displayValue={_.get(
                  accessOptions.find((element) => element.value === (_.get(values, 'siteAccessId', 108) || 'PUBLIC')),
                  'label',
                  '',
                )}
                error={_.get(errors, 'siteAccessId') && _.get(touched, 'siteAccessId')}
                helpertext={errors.siteAccessId && touched.siteAccessId && errors.siteAccessId}
                label="Access Type *"
                labelProps={{ className }}
                menuProps={{ className }}
                name="siteAccessId"
                onBlur={handleBlurDropdown}
                onChange={handleChange}
                required
                options={accessOptions}
                value={_.get(values, 'siteAccessId', 108)}
              />

              <TextInfo
                id={`${id}-access-notes`}
                className={`${className}`}
                label="Access Notes"
                multiline
                minRows={3}
                maxRows={4}
                labelProps={{ className }}
                name="accessNotes"
                onBlur={handleBlur}
                onChange={handleChange}
                value={_.get(values, 'accessNotes', '')}
              />

              <Divider className={className} />

              <SitePhotos />
            </div>
          </div>
          <Divider />
          <div className={className}>
            <div className={className}>
              <Typography className={className} variant="subtitle1" component="h3">
                Additional Notes
              </Typography>
              <Dropdown
                id={`${id}-type`}
                className={`${className} half`}
                displayValue={_.get(
                  siteTypeOptions.find((element) => element.value === _.get(values, 'locationTypeId', 92)),
                  'label',
                  '',
                )}
                error={_.get(errors, 'locationTypeId') && _.get(touched, 'locationTypeId')}
                helpertext={errors.locationTypeId && touched.locationTypeId && errors.locationTypeId}
                label="Location Type *"
                labelProps={{ className }}
                menuProps={{ className }}
                name="locationTypeId"
                onBlur={handleBlurDropdown}
                onChange={handleChange}
                options={siteTypeOptions}
                value={_.get(values, 'locationTypeId', 92)}
              />
              <Dropdown
                id={`${id}-market`}
                className={`${className} half`}
                displayValue={_.get(
                  siteMarketOptions.find(
                    (element) => element.value === (_.get(values, 'locationMarket', '') || 'LOCATION_MARKET_1'),
                  ),
                  'label',
                  '',
                )}
                label="Location Market *"
                labelProps={{ className }}
                menuProps={{ className }}
                name="locationMarket"
                onBlur={handleBlurDropdown}
                onChange={handleChange}
                options={siteMarketOptions}
                value={_.get(values, 'locationMarket', '') || 'LOCATION_MARKET_1'}
              />
              <TextInfo
                id={`${id}-location-notes`}
                className={`${className} half`}
                label="Location Notes"
                multiline={true}
                labelProps={{ className }}
                name="locationNotes"
                onBlur={handleBlur}
                onChange={handleChange}
                value={_.get(values, 'locationNotes', '')}
              />
              <TextInfo
                id={`${id}-directions`}
                className={`${className} half`}
                label="Site Directions"
                labelProps={{ className }}
                name="directions"
                onBlur={handleBlur}
                onChange={handleChange}
                value={_.get(values, 'directions', '')}
              />
            </div>
          </div>
        </Fragment>
      </AccordionDetails>
    </StyledAccordion>
  );
};
