import { gql } from '@apollo/client';
import { TariffForExtend } from './fragments';

export const getTariffForExtend = gql`
  query getTariffForExtend($input: GetTariffInput!) {
    getTariff(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtend}
`;
