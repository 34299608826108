const chargerView = {
  START_CHARGER_RESET: 'Started Charger Reset',
  CLICKED_PROFILE_TAB: 'Clicked Profile Navigation Tab',
  CLICKED_DEFAULT_PRICE_TAB: 'Clicked Default Price Navigation Tab',
  CLICKED_SESSIONS_TAB: 'Clicked Sessions Navigation Tab',
  START_CONNECTOR_STOP_CHARGE: 'Started Connector Stop Charge',
  CLICKED_CONNECTOR_STOP_CHARGE: 'Clicked Connector Stop Charge Link',
  DOWNLOAD_SESSIONS_CSV: 'Downloaded Sessions Csv File',
  SEARCHED_SESSIONS_LIST: 'Searched Session List',
  CLICKED_CUSTOM_PLAN_LINK: 'Clicked Custom Plan Link',
  CLICKED_RESERVABLE_CONNECTOR: 'Clicked Reservable Connector Button',
};

const chargersList = {
  FILTER_BY_RESERVABLE: 'Filtered By Reservable Chargers',
};

const dashboardView = {
  CHANGED_TIMEFRAME: 'Changed Dashboard Timeframe',
  CLICKED_ASSIGN_PRICE: 'Clicked Assign Price Link',
  CLICKED_ASSIGN_GROUP: 'Clicked Assign Group Link',
  CLICKED_VIEW_ALL_SITES: 'Clicked View All Sites',
  CLICKED_SITE_DETAIL: 'Clicked Site Detail Link',
};

const primaryNav = {
  CLICKED_DASHBOARD: 'Clicked Left Nav Dashboard Link',
  CLICKED_SITES: 'Clicked Left Nav Sites Link',
  CLICKED_CHARGERS: 'Clicked Left Nav Chargers',
  CLICKED_CHARGER_PRICES: 'Clicked Left Nav Charger Prices Link',
  CLICKED_CHARGER_GROUPS: 'Clicked Left Nav Charger Groups Link',
  CLICKED_CUSTOM_PLAN_PRICES: 'Clicked Left Nav Custom Plan Prices Link',
  CLICKED_REPORTS: 'Clicked Left Nav Reports Link',
  CLICKED_EVENT_LOG_REPORTS: 'Clicked Reports Charger Prices Event Log Link',
  CLICKED_RESERVATIONS: 'Clicked Left Nav Reservations Link',
  CLICKED_HELP_SUPPORT: 'Clicked Left Nav Help & Support Link',
};

const propertyDetail = {
  COPY_ADDRESS: 'Clicked Copy Address Button',
};

const reportsView = {
  FILTER_CHARGER_PRICE: 'Filtered By Charger Price',
  FILTER_CUSTOM_PLAN_PRICE: 'Filtered By Custom Plan Price',
  FILTER_SITE: 'Filtered by Site',
  FILTER_TRANSACTION_TYPE: 'Filtered by Transaction Type',
  CLICKED_CUSTOM_PLAN_LINK: 'Clicked Custom Plan Link',
  CLICKED_RESET_FILTERS: 'Clicked Reset Filters Controls',
  DOWNLOAD_SESSIONS_CSV: 'Downloaded Sessions Csv File',
  SEARCHED_SESSIONS_LIST: 'Searched Session List',
};

const siteDetails = {
  COPY_SITE_ADDRESS: 'Clicked Copy Site Address Button',
  CLICKED_CHARGER_DIRECTIONS: 'Clicked Charger Directions Button',
  CLICKED_CHARGERS_LIST_SESSION_ID: 'Clicked Current Session Id',
  CLICKED_CHARGERS_LIST_CONNECTOR_NAME: 'Clicked Charger Connection Link',
};

const sitesList = {
  FILTERS_SEARCH_CHANGE: 'Searched Site List',
  FILTERS_FILTER_CHANGE: 'Filtered Site List',
};

const utilizationBarChartCard = {
  CLICKED_KWH_TAB: 'Clicked Utilization Kwh Tab',
  CLICKED_INCOME_TAB: 'Clicked Utilization Income Tab',
};

const chargerGroupsList = {
  SEARCHED_CHARGER_GROUP_LIST: 'Searched Charger Group List',
  FILTERED_CHARGER_GROUP_LIST: 'Filtered Charger Group List',
  CLICKED_ADD_CHARGER_GROUPS: 'Clicked Add Charger Groups',
  CLICKED_CHARGER_GROUP_LINK: 'Clicked Charger Group Link',
};

const chargerGroupDetailsPage = {
  CLICKED_CHARGER_GROUP_HEADER_EDIT: 'Clicked Charger Group Header Edit Button',
  CLICKED_CHARGER_GROUP_CHARGERS_TAB: 'Clicked Charger Group Details Chargers Tab',
  CLICKED_CHARGER_GROUP_PRICES_TAB: 'Clicked Charger Group Details Prices Tab',
  CLICKED_CHARGER_GROUP_CUSTOM_PLANS_TAB: 'Clicked Charger Group Details Custom Plans Tab',
  CLICKED_CHARGER_GROUP_SAVE_DESCRIPTIONS_BUTTON: 'Clicked Charger Group Details Save Description Button',
  CLICKED_CHARGER_GROUP_CANCEL_DESCRIPTION_EDIT_BUTTON: 'Clicked Charger Group Details Cancel Edit Description Button',
};

const chargerGroupChargersList = {
  CLICKED_ADD_CHARGERS_BUTTON: 'Clicked Add Charger Group Chargers Button',
  CANCELED_CHARGER_LIST: 'Canceled Adding Chargers to List',
  SAVED_CHARGER_LIST: 'Saved Charger Group Chargers List',
  SEARCHED_CHARGER_LIST: 'Searched Charger Group Chargers List',
  DELETE_CHARGE_GROUP: 'Delete Charger Group',
};

const chargerGroupPricesTab = {
  CLICKED_EDIT_PRICES_BUTTON: 'Clicked Edit Prices Button',
};

const chargerPricesList = {
  SEARCHED_CHARGER_PRICE_LIST: 'Searched Charger Price List',
  CLICKED_ADD_CHARGER_PRICES: 'Clicked Add Charger Prices',
  CLICKED_CHARGER_PRICE_LINK: 'Clicked Charger Price Link',
};

const chargerGroupCustomPlansTab = {
  CLICKED_CUSTOM_PLAN_LEARN_MORE: 'Clicked Custom Plan Learn More',
  CLICKED_ADD_CHARGER_GROUP_TO_PLAN: 'Clicked Add Charger Group to Plan',
};

const chargerPriceSummary = {
  CLICKED_EDIT_DESCRIPTION_BUTTON: 'Clicked Edit Description Button',
  CLICKED_EDIT_CHARGER_LIST_BUTTON: 'Clicked Edit Charger List Button',
  CLICKED_EDIT_DEFAULT_PRICE_BUTTON: 'Clicked Edit Default Price Button',
  CLICKED_DONE_BUTTON: 'Clicked Done Button',
  CLICKED_EDIT_RESERVATION_PRICE_BUTTON: 'Clicked Edit Reservation Price Button',
};

const chargerPriceDetailsPage = {
  SAVED_CHARGER_PRICE_DETAILS: 'Saved Charger Price Details',
  CANCELED_CHARGER_PRICE_DETAILS: 'Canceled Charger Price Details',
};

const chargerPricePricingDetailsPage = {
  CLICKED_RESERVATIONS_TAB: 'Clicked Reservations Tab',
  CLICKED_CHARGER_PRICES_TAB: 'Clicked Charger Prices Tab',
};

const chargerPriceChargersList = {
  CLICKED_ADD_CHARGERS_BUTTON: 'Clicked Add Chargers Button',
  SAVED_CHARGER_LIST: 'Saved Charger List',
  CANCELED_CHARGER_LIST: 'Canceled Charger List',
  REMOVED_CHARGER_FROM_GROUP: 'Removed Charger From Group',
};

const chargerSelectionModal = {
  SEARCHED_CHARGER_LIST: 'Searched Charger List',
  FILTERED_CHARGER_LIST: 'Filtered Charger List', // should include filterType (state, zip code, type)
  CLICKED_CHARGER_SELECT_ALL_CHECKBOX: 'Clicked Charger Select All Checkbox',
  CLOSED_CHARGER_SELECTION_MODAL: 'Closed Charger Selection Modal',
  SAVED_CHARGER_LIST: 'Saved Charger List',
};

const customPlanList = {
  CLICKED_ADD_CUSTOM_PLAN_PRICES_BUTTON: 'Clicked Add Custom Plan Prices Button',
  FILTERED_DEACTIVATED_PLANS: 'Filtered Deactivated Plans',
  CLICKED_CUSTOM_PLAN_PRICE_LINK: 'Clicked Custom Plan Price Link',
};

const customPlanSummary = {
  CLICKED_EDIT_DESCRIPTION_BUTTON: 'Clicked Edit Description Button',
  CLICKED_EDIT_PRICING_GROUP_BUTTON: 'Clicked Edit Pricing Group Button',
  CLICKED_DEACTIVATE_PLAN_BUTTON: 'Clicked Deactivate Plan Button',
  DEACTIVATED_CUSTOM_PLAN_PRICE: 'Deactivated Custom Plan Price',
  CLICKED_DONE_BUTTON: 'Clicked Done Button',
  CLICKED_CANCEL_BUTTON: 'Clicked Cancel Button',
};

const customPlanDetails = {
  SAVED_CUSTOM_PLAN_PRICE_SCHEDULE: 'Saved Custom Plan Price Schedule',
  DUPLICATED_CUSTOM_PLAN_PRICE_SCHEDULE_GROUP: 'Duplicated Custom Plan Price Schedule Group',
  REMOVED_CUSTOM_PLAN_PRICE_SCHEDULE_GROUP: 'Removed Custom Plan Price Schedule Group',
};

const customPlanDescription = {
  SAVED_CUSTOM_PLAN_PRICE_DETAILS: 'Saved Custom Plan Price Details',
};

const helpAndSupport = {
  CLICKED_HELP_SUPPORT_BREADCRUMB: 'Clicked Help & Support Breadcrumb',
  CLICKED_HELP_SUPPORT_ARTICLE_LINK: 'Clicked Help Article Link',
  CLICKED_HELP_COSTUMER_SUPPORT_BUTTON: 'Clicked Contact Customer Support',
};

export const events = {
  chargerView,
  dashboardView,
  primaryNav,
  propertyDetail,
  reportsView,
  siteDetails,
  sitesList,
  utilizationBarChartCard,
  chargersList,
  chargerSelectionModal,
  chargerGroupsList,
  chargerGroupDetailsPage,
  chargerGroupChargersList,
  chargerGroupPricesTab,
  chargerGroupCustomPlansTab,
  chargerPricesList,
  chargerPricePricingDetailsPage,
  chargerPriceSummary,
  chargerPriceDetailsPage,
  chargerPriceChargersList,
  customPlanList,
  customPlanSummary,
  customPlanDetails,
  customPlanDescription,
  helpAndSupport,
};
