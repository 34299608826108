import { gql } from '@apollo/client';

export const listChargersV2 = gql`
  query listChargersV2($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
      edges {
        altId
        setTariffSynced
        displayTariffSynced
        tariffSyncStatus
        chargerGroup {
          altId
          chargerGroupName
        }
        chargerStatus {
          columnText
        }
        evses {
          connectors {
            reservable
            chargingLevel
            connectorStatus {
              columnText
            }
          }
        }
        chargerName
        chargerModel {
          modelName
        }
        site {
          address1
          address2
          postalCode
          administrativeArea
          siteName
          locality
          country
        }
      }
    }
  }
`;
