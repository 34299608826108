import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { theme } from '@evgo/react-material-components';
import { Styled } from './styles';
import { EditSection } from '../EditSection';
import { events } from 'src/lib/utils/analytics-events';
import { Chip } from '@material-ui/core';
import { FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS, useFlag } from 'src/config/featureFlag';
import { ChargerGroup } from 'src/@types';

export interface Props {
  tariffName?: string;
  tariffDisplayName?: string;
  chargerGroups?: ChargerGroup[];
}

export const ChargerPriceSection: React.FC<Props> = (props) => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const isNew = Boolean(queryParams.get('new'));
  const enableHMIFields = useFlag(FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS);
  return (
    <Styled>
      <EditSection
        title="Pricing Details"
        route={`/extend-plus/charger-prices/${params.altId}/edit${isNew ? '?new=true' : ''}`}
        track={events.chargerPriceSummary.CLICKED_EDIT_DESCRIPTION_BUTTON}
      >
        <p>{props.tariffName}</p>
        <br />
        {enableHMIFields && (
          <>
            <p>{props.tariffDisplayName}</p>
            <br />
          </>
        )}
        <p>Charger Groups:</p>
        <br />
        {props.chargerGroups?.map((chargerGroup, index) => (
          <Chip
            data-testid={`charger-group-chip-${index}`}
            label={chargerGroup.chargerGroupName}
            style={{ marginRight: `${theme.spacing()}px` }}
            clickable={true}
            onClick={() => navigate(`/extend-plus/charger-groups/${chargerGroup.altId}/details`)}
          />
        ))}
      </EditSection>
    </Styled>
  );
};
