import { theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  SessionAggregation,
  SessionDatePreset,
  useGetSiteForExtendQuery,
  useListChargersConsumptionForExtendQuery,
} from 'src/@types';
import { ServerErrorsException } from 'src/@types/shared';
import { AccessDenied } from 'src/components/shared/AccessDenied';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { PropertyDetail } from 'src/components/shared/PropertyDetail';
import { UtilizationBarChartCardWithQuery } from 'src/components/shared/UtilizationBarChartCardWithQuery';
import { getFullAddress } from 'src/lib/helpers';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';
import { ConsumptionMetricCards } from '../../shared/ConsumptionMetricCards';
import { ChargersList } from './ChargersList';
import { groupByEvse } from './helpers';
import { SiteDirectionsModal } from './SiteDirectionsModal';
import { StatusMetricCards } from './StatusMetricCards';
import { useModalContext } from 'src/contexts/ModalContext';
import moment from 'moment-timezone';

const StyledBox = styled(Box)`
  .grid-item {
    height: 100%;
    box-sizing: border-box;
  }

  .metric-wrapper {
    flex-direction: column;
    justify-content: space-between;

    > * {
      width: 100%;
    }

    .metric-card {
      padding: 24px;
    }
  }

  h5 {
    margin-bottom: ${theme.spacing(2)}px;
  }
`;

export const SiteDetailsView: React.FC = () => {
  const id = 'site-details-view';
  const { altId } = useParams<{ altId: string }>();
  const { modalName, modalProps, modalVisible, setModalState } = useModalContext();
  const { track } = useAnalytics();

  const { data, loading, error, fetchMore } = useGetSiteForExtendQuery({
    variables: { siteInput: { altId: altId || '' } },
  });

  const {
    data: consumptionData,
    loading: loadingConsumptionData,
    error: consumptionDataError,
  } = useListChargersConsumptionForExtendQuery({
    variables: {
      input: {
        startDate: moment()
          .tz(data?.getSiteForExtend?.timeZone || 'UTC')
          .startOf('day')
          .toISOString(),
        endDate: moment()
          .tz(data?.getSiteForExtend?.timeZone || 'UTC')
          .endOf('day')
          .toISOString(),
        siteIds: [altId || ''],
      },
    },
    skip: loading || !data?.getSiteForExtend,
    pollInterval: 30000,
  });

  if (loading) {
    return <CircularProgress data-testid={`${id}-loading`} />;
  }

  if (error?.graphQLErrors) {
    const { code } = error.graphQLErrors[0].extensions?.exception as ServerErrorsException;
    if (code === 'EXTEND_HOST_ACCESS_DENIED') {
      return <AccessDenied />;
    }
  }

  if (error || !data?.getSiteForExtend) {
    return (
      <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Typography>{error?.message || 'Something went wrong'} </Typography>
      </Box>
    );
  }

  const site = data.getSiteForExtend;

  const fullAddress = getFullAddress({
    streetAddress: site.address1,
    unit: site.address2,
    city: site.locality,
    state: site.administrativeArea,
    postalCode: site.postalCode,
  });

  const listEvses = groupByEvse(consumptionData?.listChargersConsumptionForExtend || []);

  return (
    <PageContent
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbLink data-testid={`${id}-home-link`} to="/extend-plus/sites">
            Sites
          </BreadcrumbLink>
          <BreadcrumbText>{site.displayName}</BreadcrumbText>
        </Breadcrumbs>
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <div>
              <Typography variant="h4" data-testid="property-title-root">
                {site.displayName}
              </Typography>
              <PropertyDetail
                title={'Site Address'}
                content={fullAddress}
                allowCopyToClipboard
                contentMaxWidth={190}
                eventName={events.siteDetails.COPY_SITE_ADDRESS}
              />
            </div>
          }
        />
      }
    >
      <StyledBox>
        <Box mb={2}>
          <Button
            onClick={() => {
              setModalState({
                modalName: 'SiteDirectionsModal',
                modalVisible: true,
                modalProps: {
                  data: site,
                  fetchMore,
                },
              });
              track(events.siteDetails.CLICKED_CHARGER_DIRECTIONS);
            }}
            color="secondary"
            variant="outlined"
            size="large"
            data-testid="site-directions-button"
          >
            Charger Directions
          </Button>
        </Box>
        <Grid className="card" container spacing={4}>
          <Grid item xs={9}>
            <Card className="grid-item" data-testid="graph-site-utilization-card">
              <UtilizationBarChartCardWithQuery
                title="Site Utilization"
                siteName={data.getSiteForExtend.displayName || ''}
                aggregateBy={SessionAggregation.Day}
                datePreset={SessionDatePreset.Last_7d}
                siteIds={[altId as string]}
              />
            </Card>
          </Grid>
          <Grid className="metric-wrapper" container item xs={3} spacing={2}>
            <ConsumptionMetricCards siteIds={altId ? [altId] : []} />
            <StatusMetricCards data={listEvses} />
          </Grid>
        </Grid>
        <ChargersList data={listEvses} loading={loadingConsumptionData} error={consumptionDataError} />
        <SiteDirectionsModal {...modalProps} open={modalName === 'SiteDirectionsModal' && modalVisible} />
      </StyledBox>
    </PageContent>
  );
};
