import { MutationTuple, OperationVariables, QueryResult } from '@apollo/client';
import { ListChargersV2Query } from './';
import { ReactElement } from 'react';
import type {
  ListTransactionsV2ExtendQuery,
  ListTransactionsV2ExtendForExportQuery,
  ListTransactionsV2Query,
  Mutation,
} from '.';

export enum CardType {
  Emaid = 286,
  PinCode = 287,
  Virtual = 288,
  OneTime = 289,
  VehicleId = 290,
  RFID = 291,
}

export type ConnectorStatuses =
  | 'Available'
  | 'Charging'
  | 'Discharging'
  | 'Faulted'
  | 'Finishing'
  | 'New'
  | 'Paused'
  | 'Preparing'
  | 'Reserved'
  | 'Unavailable'
  | 'Unknown'
  | null;

export enum ConnectorStatusId {
  Available = '184',
  Charging = '141',
  Unavailable = '137',
  Faulted = '145',
  Unknown = '138',
}

export enum ChargerGroupTypeId {
  ChargerGroups = 401,
  CustomPlans = 402,
}

export interface LabelValue<TValue> {
  label: string;
  value: TValue;
}

export interface Snackbar {
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: ReactElement<any, any> | Element;
}

export interface HostError {
  error: { type: string } | null;
}

export type DataAttributeKey = `data-${string}`;

export interface InputPropsWithData extends React.HTMLAttributes<HTMLInputElement> {
  [key: DataAttributeKey]: string;
}

export interface AddCardParams {
  internalNumber: string;
  externalNumber: string;
  brand: string;
}

export interface CreateExtendUserParams {
  username: string;
  firebaseId: string;
  hostIds: string[];
}

export interface ServerErrors {
  errorMessage: { path: string; message: string }[];
  path: string;
  message: string;
  fields: { field: string; message: string }[];
  extensions: { exception: ServerErrorsException; message: string };
}

export type ServerErrorsException = {
  code: string;
  fields: { message: string; field: string }[];
  message: string;
  stacktrace: string[];
};

export type FetchMore = QueryResult<never, never>['fetchMore'];

export type SetChecked = React.Dispatch<React.SetStateAction<string[]>>;
export type SetCheckAll = React.Dispatch<React.SetStateAction<boolean>>;

export type MatOnChangePageEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | null;

export type MutationOp = MutationTuple<Mutation, OperationVariables>[0];

export interface ModelOptions {
  [key: string]: { enumValues: LabelValue<string>[] };
}

export enum RoleApp {
  Falcon = 'falcon',
  OperatorPortal = 'operatorPortal',
}

export enum FalconRole {
  SuperAdmin = 'admin',
}

export enum OperatorPortalRole {
  PartnerAdmin = 'partnerAdmin',
  SuperAdmin = 'superAdmin',
}

export type Role = FalconRole | OperatorPortalRole;

export type SnackbarType = 'success' | 'error' | 'warning' | 'info' | 'system';

export type ListChargersV2Charger = NonNullable<NonNullable<ListChargersV2Query['listChargersV2']>['edges']>[0];

export type ListTransactionsV2Transaction = NonNullable<
  NonNullable<NonNullable<ListTransactionsV2Query['listTransactionsV2']>['edges']>[0]
>;

export type ListTransactionsV2TransactionItem = NonNullable<ListTransactionsV2Transaction['items']>[0];

export type ListTransactionsV2ExtendTransaction = NonNullable<
  NonNullable<ListTransactionsV2ExtendQuery['listTransactionsV2']>['edges']
>[0];

export type ListTransactionsV2ExtendForExportTransaction = NonNullable<
  NonNullable<ListTransactionsV2ExtendForExportQuery['listTransactionsV2']>['edges']
>[0];

export enum DisplayRank {
  /** Rank 1 */
  One = 1,
  /** Rank 2 */
  Two = 2,
  /** Rank 3 */
  Three = 3,
  /** Not Displayed */
  NotDisplayed = 99,
}
