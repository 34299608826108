import { gql } from '@apollo/client';
import { TariffForExtend } from './fragments/Tariff';

export const createTariffForExtend = gql`
  mutation createTariffForExtend($input: CreateTariffInput!) {
    createTariff(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtend}
`;
