import { gql } from '@apollo/client';

export const getExtendUser = gql`
  query getExtendUser($extenduserInput: GetExtendUserInput) {
    getExtendUser(input: $extenduserInput) {
      altId
      hosts {
        edges {
          id
          altId
          hostName
          customPlansEnabled
        }
      }
    }
  }
`;
