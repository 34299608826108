import React from 'react';
import { ChargerPricing, useListTariffsForExtendQuery } from 'src/@types';
import TariffSyncStatus from 'src/components/shared/TariffSyncStatus';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import config from 'src/config';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import _ from 'lodash';
import { formatPrice } from '../../Details/helpers';

type ColumnDataProps = {
  data: ChargerPricing;
};

const ChargerGroupName: React.FC<ColumnDataProps> = ({ data }) => {
  const { track } = useAnalytics();
  const to = `/extend-plus/charger-prices/${data?.altId}/details`;
  return (
    <TableLink to={to} onClick={() => track(events.chargerPricesList.CLICKED_CHARGER_PRICE_LINK)}>
      {data.tariffName}
    </TableLink>
  );
};

const DefaultPricing: React.FC<ColumnDataProps> = ({ data }) => {
  const { pricing } = data?.defaultPricing || {};
  const perKwh = pricing?.perKwh || pricing?.perKwh === 0 ? formatPrice(pricing?.perKwh) : null;
  const perMinute = pricing?.perMinute || pricing?.perMinute === 0 ? formatPrice(pricing?.perMinute) : null;
  const perSession = pricing?.perSession || pricing?.perSession === 0 ? formatPrice(pricing?.perSession) : null;
  return (
    <span>
      {perKwh && (
        <>
          ${perKwh} per kWh <br />
        </>
      )}
      {perMinute && (
        <>
          ${perMinute} per min
          <br />
        </>
      )}
      {perSession && <>${perSession} per session</>}
    </span>
  );
};

const TOUPricing: React.FC<ColumnDataProps> = ({ data }) => {
  const hasCustomPricing = !_.isEmpty(data.customPricing) ? 'Yes' : 'No';
  return <span>{hasCustomPricing}</span>;
};

const columns: Column[] = [
  { key: 'tariffName', label: 'Name', sortable: true, width: '20%', component: ChargerGroupName },
  { key: 'defaultPrice', label: 'Default Price', sortable: false, width: '20%', component: DefaultPricing },
  { key: 'touPrice', label: 'Has Time of Use Pricing', sortable: false, width: '20%', component: TOUPricing },
  {
    key: 'chargerGroupCount',
    label: '# of Charger Groups',
    sortable: true,
    numeric: true,
    width: '10%',
  },
  {
    key: 'chargerCount',
    label: '# of Chargers',
    sortable: true,
    numeric: true,
    width: '10%',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    width: '20%',
    component: TariffSyncStatus,
  },
];

export const ChargerPriceGroupsList: React.FC = () => {
  const { data, loading, error } = useListTariffsForExtendQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const edges = data?.listTariffs?.edges || [];
  const formattedData = edges.map((tariff) => {
    const chargerGroupCount = tariff?.chargerGroups?.length || 0;
    let chargerCount = 0;
    tariff?.chargerGroups?.forEach((chargerGroup) => {
      if (chargerGroup?.chargers && chargerGroup?.chargers.total) chargerCount += chargerGroup.chargers.total;
    });
    return { ...tariff, chargerCount, chargerGroupCount };
  });

  return (
    <>
      <Table
        id="charger-groups-table"
        data-testid="charger-groups-table"
        columns={columns}
        data={formattedData}
        loading={loading}
        error={error}
        sorting={{
          sortBy: 'tariffName',
          sortDirection: Direction.Asc,
        }}
        noDataMessage="Create a price to easily apply prices to your chargers"
      />
    </>
  );
};
