import { gql } from '@apollo/client';

export const listTariffPriceLog = gql`
  query listTariffPriceLog($input: ListTariffsInput!, $eventLogInput: ListEventLogInput!) {
    listTariffs(input: $input) {
      edges {
        altId
        tariffName
        defaultPricing {
          displayName
          pricing {
            perKwh
            perMinute
            perSession
          }
        }
        chargerGroups {
          chargers {
            edges {
              site {
                timeZone
              }
            }
          }
        }
        eventLog(input: $eventLogInput) {
          edges {
            eventDate
            eventText
            eventType {
              id
              columnValue
            }
            eventTypeId
            entityId
            entityType
            jsonData
            user {
              altId
              username
            }
          }
        }
      }
      total
    }
  }
`;
