import styled from 'styled-components';
import { Dispatch } from 'react';
import { theme } from '@evgo/react-material-components';
import { Autorenew as AutorenewIcon } from '@material-ui/icons';
import { addMonths, endOfDay, isAfter } from 'date-fns';
import { Box, Button } from '@material-ui/core';

import { Card, CardHeader } from 'src/components/shared/Card';
import { DateRangeField } from 'src/components/shared/DateRangeField';
import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';

import { Action, State } from './filterHelpers';
import { staticRanges } from '../../ReportFilter/utils';

const { spacing } = theme;

export type ReportFilterProps = {
  state: State;
  dispatch: Dispatch<Action>;
  resetPagination: () => void;
};

const StyledDateRangeField = styled(DateRangeField)`
  margin-left: ${spacing(2)}px;
  [class*='MuiFormControl-root'] {
    min-width: 230px;
  }
`;

export function EventLogFilter({ state, dispatch, resetPagination }: ReportFilterProps) {
  const today = endOfDay(new Date());
  const max = addMonths(state.filter.dateRange.startDate, 6);
  const maxDate = isAfter(max, today) ? today : max;

  return (
    <Card sx={{ pb: 3, my: 4 }} data-testid="report-filters">
      <CardHeader title="Report Filters" />
      <Box display="flex" alignItems="flex-start">
        <Box pt={1} pr={1}>
          <StyledDateRangeField
            id="date-filter"
            placeholder="Select date range"
            value={state.filter.dateRange}
            onChange={(dateRange) => {
              resetPagination();
              dispatch({ type: 'setDateRange', dateRange });
            }}
            DateRangePickerProps={{
              inputRanges: [],
              staticRanges: staticRanges,
              maxDate,
            }}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            id="chargerPriceGroups"
            label="Charger Price Group"
            value={state.filter.entityIds}
            options={state.initial.tariffs.map(({ id, tariffName }) => ({
              value: id || '',
              label: tariffName || '',
            }))}
            onChange={(value) => {
              resetPagination();
              dispatch({ type: 'setChargerPriceGroups', entityIds: value });
            }}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            id="eventTypes"
            label="Event Type"
            value={state.filter.eventTypes}
            options={state.initial.eventTypes.map(({ eventName, value }) => ({
              value: value || '',
              label: eventName || '',
            }))}
            onChange={(value) => {
              resetPagination();
              dispatch({ type: 'setEventTypes', eventTypes: value });
            }}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            id="users"
            label="User"
            value={state.filter.usernames}
            options={state.initial.usernames.map(({ username }) => ({
              value: username || '',
              label: username || '',
            }))}
            onChange={(value) => {
              resetPagination();
              dispatch({ type: 'setUsers', usernames: value });
            }}
          />
        </Box>
        <Box pt={2} pl={2}>
          <Button
            role="button"
            data-testid="reset-filters-button"
            color="secondary"
            onClick={() => {
              resetPagination();
              dispatch({ type: 'resetFilter' });
            }}
            startIcon={<AutorenewIcon />}
          >
            Reset filters
          </Button>
        </Box>
      </Box>
    </Card>
  );
}
