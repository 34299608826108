import React, { Fragment } from 'react';
import { Formik } from 'formik';
import { useParams, useSearchParams } from 'react-router-dom';
import { Styled } from './styles';
import { EditSection } from '../EditSection';
import { TariffCustomPricing } from 'src/@types';
import { Box } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { CustomPricingInput } from 'src/components/shared/CustomPricingInput';
export interface Props {
  customPricing?: Array<TariffCustomPricing>;
  loading?: boolean;
  error?: ApolloError | undefined;
}

export const TimeSpecificPriceSection: React.FC<Props> = ({ customPricing }) => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  return (
    <Styled>
      <EditSection
        title="Time-specific Prices"
        subtitle="Prices defined for specific time frames"
        route={`/extend-plus/charger-prices/${params.altId}/pricing#custom-pricing${isNew ? '?new=true' : ''}`}
      >
        {customPricing?.length ? (
          <Formik initialValues={{ customPricing }} onSubmit={() => {}} enableReinitialize>
            <Fragment>
              {customPricing.map((item, index) => (
                <CustomPricingInput
                  showHmiDisplay={true}
                  key={index}
                  index={index}
                  title={`Time of use price ${index + 1}`}
                  name={`customPricing.${index}`}
                  readonly={true}
                />
              ))}
            </Fragment>
          </Formik>
        ) : (
          <Box display="flex" justifyContent="space-between" paddingTop={2} width={520}>
            <span>N/A</span>
          </Box>
        )}
      </EditSection>
    </Styled>
  );
};
