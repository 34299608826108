import { useMemo } from 'react';
import { Maybe, EventLog } from 'src/@types';
import {
  FormattedEventForExport,
  formatEventForExport,
} from 'src/components/views/ExtendPlusView/Reports/EventLog/ExportButton';

type Props = {
  events: Maybe<EventLog>[];
};

export function useFormattedEventForExport({ events }: Props) {
  const rows = useMemo<FormattedEventForExport[]>(
    () =>
      (events || []).map((edge) => {
        return formatEventForExport(edge);
      }),
    [events],
  );
  return rows;
}
