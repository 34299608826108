import { ApolloError } from '@apollo/client';
import { theme } from '@evgo/react-material-components';
import React from 'react';
import { ChargerGroup, ListChargerGroupsForExtendQuery } from 'src/@types';
import ChargerGroupSyncStatus from 'src/components/shared/ChargerGroupSyncStatus';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import { formatDate } from 'src/lib/helpers/formatDate';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';

type Props = {
  data: NonNullable<ListChargerGroupsForExtendQuery['listChargerGroups']>['edges'];
  loading: boolean;
  error?: ApolloError;
};

type ColumnDataProps = {
  data: ChargerGroup;
};

const ChargerGroupName: React.FC<ColumnDataProps> = ({ data }) => {
  const { track } = useAnalytics();
  const to = `/extend-plus/custom-plans/${data?.altId}/details`;
  return (
    <TableLink
      style={{ color: data.deletedAt ? theme.palette.grey['400'] : undefined }}
      onClick={() => track(events.customPlanList.CLICKED_CUSTOM_PLAN_PRICE_LINK)}
      to={to}
    >
      {data.chargerGroupName}
    </TableLink>
  );
};

const ColumnWrapper = styled.span<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? theme.palette.grey['400'] : 'inherit')};
`;

const CGDescriptionColumn: React.FC<ColumnDataProps> = ({ data }) => (
  <ColumnWrapper disabled={!!data.deletedAt}>{data.description}</ColumnWrapper>
);

const CGTotalChargersColumn: React.FC<ColumnDataProps> = ({ data }) => (
  <ColumnWrapper disabled={!!data.deletedAt}>{data.chargers.total}</ColumnWrapper>
);

const columns: Column[] = [
  { key: 'chargerGroupName', label: 'Name', sortable: true, width: '33%', component: ChargerGroupName },
  { key: 'description', label: 'Description', sortable: true, width: '33%', component: CGDescriptionColumn },
  {
    key: 'chargers.total',
    label: '# of Chargers',
    sortable: true,
    numeric: true,
    width: '10%',
    component: CGTotalChargersColumn,
  },
  {
    key: 'createdAt',
    label: 'Create Date',
    sortable: true,
    width: '20%',
    format: (value) => formatDate(`${value}`),
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    width: '20%',
    component: ChargerGroupSyncStatus,
  },
];

export const CustomPlanPriceGroupsList: React.FC<Props> = ({ data, loading, error }) => {
  return (
    <Table
      id="charger-groups-table"
      data-testid="charger-groups-table"
      columns={columns}
      data={data}
      loading={loading}
      error={error}
      sorting={{
        sortBy: 'chargerGroupName',
        sortDirection: Direction.Asc,
      }}
      noDataMessage="Create a plan to provide custom plan prices and access to your customers"
    />
  );
};
