/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from '@evgo/react-material-components';
import { Typography } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../../../firebase';
import { Styled } from './styles';
import { validations as validationSchema } from './ResetPasswordForm/validations';
import { ResetPasswordForm, initialValues } from './ResetPasswordForm';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
import { useAuthState } from 'react-firebase-hooks/auth';

export const ResetPasswordView: React.FC = () => {
  const id = 'reset-password-view';
  const snackbar = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser, firebaseLoading] = useAuthState(auth);

  const resetResponse = () => {
    snackbar.success(
      'If we have an account matching your email address, you will receive an email within 5 minutes with a link to reset your password',
    );
    navigate('/login');
  };
  const handleSubmit = (input: { email: string }) => {
    sendPasswordResetEmail(auth, input.email)
      .then(() => {
        resetResponse();
      })
      .catch(() => {
        resetResponse();
      });
  };

  if (firebaseLoading) return null;
  if (currentUser) return <Navigate to={(location?.state as any)?.from || '/'} />;

  return (
    <Styled className={id}>
      <header data-testid={`${id}-header`}>
        <Typography data-testid={`${id}-title`} component="h1" variant="h6">
          Forgot Password?
        </Typography>
      </header>

      <Divider />

      <section data-testid={`${id}-form-container`}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <ResetPasswordForm />
        </Formik>
      </section>
    </Styled>
  );
};
