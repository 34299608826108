import { Box } from '@material-ui/core';
import { SearchField } from 'src/components/shared/SearchField';
import { Filters } from '../ChargersList';
import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';
import { Options } from 'src/lib/helpers';
import { useGetSitesShallowForExtendQuery, useListChargerGroupsForExtendQuery } from 'src/@types';
import { ConnectorStatusId } from 'src/@types/shared';
import { SimpleSelect } from 'src/components/shared/SimpleSelect';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

export enum ChargingLevelId {
  L2 = '324',
  DCFC = '322,323',
}

type ChargerSelectionFilterProps = {
  filters: Filters;
  search: string;
  onSearch: (val: string) => void;
  onFilter: (val: Filters) => void;
};

export function ChargersFilter({ filters, search, onSearch, onFilter }: ChargerSelectionFilterProps) {
  const { track } = useAnalytics();

  const statusOptions: Options[] = Object.keys(ConnectorStatusId).map((key) => {
    return {
      value: (ConnectorStatusId as { [key: string]: string })[key],
      label: key,
    };
  });
  const { data } = useGetSitesShallowForExtendQuery({
    variables: {},
  });

  const { data: chargerGroupData } = useListChargerGroupsForExtendQuery({
    variables: {
      input: {
        filter: {
          chargerGroupTypeId: {
            eq: 401,
          },
        },
      },
    },
  });
  const chargerGroups = chargerGroupData?.listChargerGroups?.edges || [];
  const chargerGroupOptions = chargerGroups
    ? Array.from(new Set(chargerGroups.map((chargerGroup) => chargerGroup?.chargerGroupName || '')))
        .sort()
        .map((value) => ({
          value,
          label: value,
        }))
    : [];

  chargerGroupOptions.unshift({
    value: 'Unassigned',
    label: 'Unassigned',
  });

  const sites = data?.getSitesForExtend || [];
  const postalCodes = Array.from(new Set(sites.map((site) => site?.postalCode)))
    .sort()
    .map((value) => ({
      value: value || '',
      label: value || '',
    }));
  const siteNames = Array.from(new Set(sites.map((site) => site?.siteName)))
    .sort()
    .map((value) => ({
      value: value || '',
      label: value || '',
    }));

  return (
    <Box display="flex" alignItems="flex-start">
      <Box display="flex" alignItems="flex-start">
        <Box pt={3} pl={2}>
          <SearchField initialValue={search} onChange={onSearch} />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            label="Status"
            options={statusOptions}
            onChange={(type) =>
              onFilter({
                ...filters,
                connectorStatusId: type.length
                  ? type
                      .join(',')
                      .split(',')
                      .map((val) => Number(val))
                  : [],
              })
            }
            debounce={500}
            value={filters.connectorStatusId.map((e) => e.toString())}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            label="Site"
            options={siteNames}
            onChange={(type) =>
              onFilter({
                ...filters,
                siteName: type,
              })
            }
            debounce={500}
            value={filters.siteName}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            label="Zip Code"
            options={postalCodes}
            onChange={(type) =>
              onFilter({
                ...filters,
                sitePostalCode: type,
              })
            }
            debounce={500}
            value={filters.sitePostalCode}
          />
        </Box>
        <Box pt={0} pl={2}>
          <SimpleSelect
            label="Reservable"
            options={[
              { label: 'All', value: '' },
              { label: 'Yes', value: '1' },
              { label: 'No', value: '0' },
            ]}
            onChange={(value) => {
              onFilter({
                ...filters,
                connectorIsReservable: value === '' ? undefined : Boolean(Number(value)),
              });
              track(events.chargersList.FILTER_BY_RESERVABLE);
            }}
            debounce={500}
            value={filters.connectorIsReservable === undefined ? '' : Number(filters.connectorIsReservable).toString()}
            defaultValue={''}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            label="Charger Group"
            options={chargerGroupOptions.map((option) => ({
              ...option,
              disabled:
                !filters?.chargerGroupName?.includes(option.value) &&
                ((option.value === 'Unassigned' &&
                  filters?.chargerGroupName?.some((value) => value !== 'Unassigned')) ||
                  (option.value !== 'Unassigned' && filters?.chargerGroupName?.includes('Unassigned'))),
            }))}
            onChange={(group) =>
              onFilter({
                ...filters,
                chargerGroupName: group,
              })
            }
            debounce={500}
            value={filters.chargerGroupName || []}
          />
        </Box>
      </Box>
    </Box>
  );
}
