import moment from 'moment';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { TableLink } from 'src/components/shared/TableLink';
import { TransactionV2, TransactionItemV2, Maybe } from 'src/@types';
import { ListTransactionsV2ExtendForExportTransaction } from 'src/@types/shared';
import { Column } from 'src/components/shared/Table';
import { formatDate } from 'src/lib/helpers/formatDate';
import { formatTime } from 'src/lib/helpers/formatTime';
import { formatDuration } from 'src/lib/helpers/formatDuration';
import { SimpleColumn } from '../BasicTable';
import { ListTransactionsV2ExtendTransaction } from 'src/@types/shared';
import styled from 'styled-components';

export type FormattedTransactionForExport = {
  'Transaction ID': string | null | undefined;
  'Transaction Type': string | null | undefined;
  'Session ID': string | null | undefined;
  Site: string | null | undefined;
  'Charger Name': string | null | undefined;
  'Connector Type': string | null | undefined;
  'Start Source': string | null | undefined;
  'UTC Date': string | null | undefined;
  'UTC Start Time': string | null | undefined;
  'UTC End Time': string | null | undefined;
  'Charger Date': string | null | undefined;
  'Charger Start Time': string | null | undefined;
  'Charger End Time': string | null | undefined;
  'Charger TZ': string | null | undefined;
  'Charger TZ Offset': string | null | undefined;
  'Total Session Time': string | null | undefined;
  'Total kWh': string | null | undefined;
  'Tax Amount': string | null | undefined;
  'Total Session Cost': string | null | undefined;
  'eXtend Calculated Transaction'?: string | null | undefined;
  'Credit Card Payment ID': string | null | undefined;
};

export const TextItalic = styled('div')({
  fontStyle: `italic`,
});

export const CustomPlanName: React.FC<{ data: TransactionV2 }> = ({ data }) => {
  const { track } = useAnalytics();
  if (!data?.session?.plan?.chargerGroup) return <>{'N/A'}</>;
  const to = `/extend-plus/custom-plans/${data.session?.plan?.chargerGroup?.altId}/details`;
  return (
    <TableLink to={to} onClick={() => track(events.chargerView.CLICKED_CUSTOM_PLAN_LINK)}>
      {data.session?.plan?.chargerGroup.chargerGroupName}
    </TableLink>
  );
};

export const getTz: (edge: Maybe<TransactionV2> | Maybe<ListTransactionsV2ExtendTransaction>) => string = (edge) => {
  return edge?.session?.connector?.evse?.charger?.site?.timeZone || '';
};

export const transactionItemColumns: SimpleColumn<TransactionItemV2>[] = [
  { key: 'productType.columnText', label: 'Type' },
  {
    key: 'unitPrice',
    label: 'Price',
    formatWithRowData: ({ unitPrice, calculatedUnitPrice }) =>
      `$${(calculatedUnitPrice || unitPrice)?.toFixed(2) || 0}`,
  },
  { key: 'quantity', label: 'Quantity' },
  {
    key: 'subtotal',
    label: 'Subtotal',
    formatWithRowData: ({ unitPrice, calculatedUnitPrice, quantity }) =>
      `$${((calculatedUnitPrice || unitPrice || 0) * (quantity || 0)).toFixed(2) || 0}`,
  },
];

export const transactionColumns: Column<TransactionV2>[] = [
  { key: 'vendorId', label: 'ID', sortable: true, width: '8%' },
  {
    key: 'dateTime',
    label: 'Date Time',
    formatWithRowData: ({ session }) => {
      const startTime = session?.startTimeLocal;
      const endTime = session?.endTimeLocal;
      const timeZone = session?.connector?.evse?.charger?.site?.timeZone;
      const zone = timeZone ? moment.utc(session?.startTimeLocal).tz(timeZone).format('z') : '';
      return startTime ? `${formatDate(startTime)} ${formatTime(startTime)} - ${formatTime(endTime)} ${zone}` : '-';
    },
    sortable: true,
    width: '12%',
    sortName: 'startDate',
  },
  { key: 'type.columnText', label: 'Type', sortable: false, width: '12%' },
  {
    key: 'session.connector.evse.charger.site.siteName',
    label: 'Site',
    sortable: true,
    width: '12%',
    sortName: 'siteName',
  },
  {
    key: 'chargerAndConnector',
    label: 'Charger/Connector',
    component: ({ data: { session } }) => (
      <span>
        <div>{session?.connector?.evse?.charger?.chargerName}</div>
        <div>{session?.connector?.connectorType?.columnText}</div>
      </span>
    ),
    sortable: true,
    width: '12%',
    sortName: 'chargerName',
  },
  { key: 'session.startSource.columnText', label: 'Start Source', sortable: false, width: '8%' },
  {
    key: 'session.powerValue',
    label: 'Total kWh',
    sortable: true,
    width: '8%',
    numeric: true,
    formatWithRowData: ({ session }) =>
      session?.meterEnd ? ((session?.meterEnd - (session?.meterStart || 0)) / 1000).toFixed(4) : '-',
    sortName: 'kwh',
  },
  {
    key: 'session.sessionDuration',
    label: 'Duration',
    sortable: true,
    numeric: true,
    width: '8%',
    formatWithRowData: ({ session }) =>
      formatDuration(
        moment.duration(moment(session?.endTime ? session.endTime : undefined).diff(session?.startTime)).asSeconds(),
      ),
    sortName: 'duration',
  },
  {
    key: 'session.plan.chargerGroup.chargerGroupName',
    label: 'Custom Plan',
    sortable: true,
    width: '8%',
    component: CustomPlanName,
    sortName: 'planName',
  },
  {
    key: 'taxRate',
    label: 'Tax Rate',
    formatWithRowData: ({ items }) => {
      const maxTaxRate = items?.reduce((acc, cv) => {
        const taxRate = cv?.taxRate || 0;
        return taxRate > acc ? taxRate : acc;
      }, 0);
      return maxTaxRate != null ? `${maxTaxRate}%` : '-';
    },
    sortable: true,
    numeric: true,
    width: '8%',
  },
  {
    key: 'tax',
    label: 'Tax Amount',
    formatWithRowData: ({ tax, calculatedTaxAmount }) => `$${(calculatedTaxAmount || tax)?.toFixed(2) || 0}`,
    sortable: true,
    numeric: true,
    width: '8%',
  },
  {
    key: 'calculatedAmount',
    label: 'Total Cost',
    formatWithRowData: ({ amount, calculatedAmount, isCalculated }) => {
      const content = `$${Number(calculatedAmount || amount || 0).toFixed(2) || 0}`;
      return isCalculated ? <TextItalic>{content}</TextItalic> : content;
    },
    sortable: true,
    numeric: true,
    width: '8%',
  },
  { key: 'session.connector.connectorType.columnText', label: 'Connector Type', sortable: false, width: '10%' },
];

type ColumnsByKey = { [key: string]: Column };

export const transactionColumnsByKey = transactionColumns.reduce((acc: ColumnsByKey, cv) => {
  acc[cv.key] = cv;
  return acc;
}, {});

export const formatTransactionForExport = (
  edge: Maybe<ListTransactionsV2ExtendForExportTransaction>,
): FormattedTransactionForExport => {
  return {
    'Transaction ID': edge?.vendorId,
    'Transaction Type': edge?.type?.columnText,
    'Session ID': edge?.session?.vendorId,
    Site: edge?.session?.connector?.evse?.charger?.site?.siteName,
    'Charger Name': edge?.session?.connector?.evse?.charger?.chargerName,
    'Connector Type': edge?.session?.connector?.connectorType?.columnText,
    'Start Source': edge?.session?.startSource?.columnText,
    'UTC Date': formatDate(edge?.session?.startTime),
    'UTC Start Time': formatTime(edge?.session?.startTime),
    'UTC End Time': formatTime(edge?.session?.endTime),
    'Charger Date': formatDate(edge?.session?.startTimeLocal),
    'Charger Start Time': formatTime(edge?.session?.startTimeLocal),
    'Charger End Time': formatTime(edge?.session?.endTimeLocal),
    'Charger TZ': getTz(edge) ? moment(edge?.session?.startTime).tz(getTz(edge)).format('z') : '-',
    'Charger TZ Offset': getTz(edge) ? moment(edge?.session?.startTime).tz(getTz(edge)).format('Z') : '-',
    'Total Session Time': edge?.session?.endTime
      ? formatDuration(
          moment
            .duration(moment(edge?.session?.endTime ? edge.session.endTime : undefined).diff(edge.session?.startTime))
            .asSeconds(),
        )
      : '-',
    'Total kWh': edge?.session?.meterEnd
      ? ((edge.session?.meterEnd - (edge?.session?.meterStart || 0)) / 1000).toFixed(4)
      : '-',
    'Tax Amount': `$${(edge?.calculatedTaxAmount || edge?.tax)?.toFixed(2)}`,
    'Total Session Cost': `$${Number(edge?.calculatedAmount || edge?.amount || 0).toFixed(2)}`,
    'eXtend Calculated Transaction': edge?.isCalculated ? 'true' : 'false',
    'Credit Card Payment ID': edge?.paymentTxId,
  };
};
