import { useRef, useCallback } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import config from 'src/config';
import { ListTransactionsV2FilterInput, useListTransactionsV2ExtendForExportLazyQuery } from 'src/@types';
import { useDataContext } from 'src/components/shared/DataContext/useDataContext';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { useSnackbar } from 'src/lib/hooks/useSnackbar';
import { useFormattedTransactionsForExport } from 'src/lib/hooks/useFormattedTransactionsForExport';

type Props = {
  chargerId: string;
  filter: ListTransactionsV2FilterInput;
};

export default function ExportButton({ chargerId, filter }: Props) {
  const ref = useRef<CSVLink>();
  const [dataRef, setData] = useDataContext();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const today = moment(new Date()).format('YYYY-MM-DD');
  const [load, { data, loading }] = useListTransactionsV2ExtendForExportLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const onClick = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { data } = await load({
      variables: {
        input: {
          page: 0,
          pageSize: config.maxPageSize,
          filter,
        },
      },
    });

    const empty = (data?.listTransactionsV2?.edges || []).length === 0;

    if (!empty) {
      // @ts-ignore
      ref.current?.link?.click();
      track(events.chargerView.DOWNLOAD_SESSIONS_CSV);
    } else {
      snackbar.error("There's no data to export.");
    }
  }, [load, ref, snackbar, track, filter]);

  const rows = useFormattedTransactionsForExport({ transactions: data?.listTransactionsV2?.edges || [] });

  return (
    <>
      <Button
        onClick={onClick}
        disabled={loading}
        color="secondary"
        variant="contained"
        ref={dataRef}
        {...setData('export-button')}
      >
        <GetApp /> Download CSV
      </Button>
      <CSVLink
        ref={ref as never}
        data={rows}
        filename={`${today}_${chargerId}_sessions.csv`}
        {...setData('csv-link')}
      />
    </>
  );
}
