import { gql } from '@apollo/client';
import { ChargerGroupForExtend } from './fragments';

export const listChargerGroupsForChargerPrices = gql`
  query listChargerGroupsForChargerPrices($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        ...ChargerGroupForExtend
        tariff {
          altId
        }
        chargers: chargersLoaded {
          edges {
            altId
          }
          total
        }
      }
      total
    }
  }
  ${ChargerGroupForExtend}
`;
