import { gql } from '@apollo/client';

export const listExtendUsersForHost = gql`
  query listExtendUsersForHost($input: ListExtendUsersInput!) {
    listExtendUsersForHost(input: $input) {
      edges {
        altId
        username
      }
    }
  }
`;
