import { theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ChargerGroupSyncStatus, useGetChargerGroupForExtendQuery } from 'src/@types';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { PageActions } from 'src/components/shared/PageActions';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import ScreenSnackbar from 'src/components/shared/ScreenSnackbar';
import ChargerGroupSyncStatusComponent from 'src/components/shared/ChargerGroupSyncStatus';
import { Title } from 'src/components/shared/Title';
import { useConfirmationDialog } from 'src/lib/hooks';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';
import { CustomPlanPriceStepper, Step } from '../CustomPlanPriceStepper';
import { DeactivatePlanButton } from './DeactivatePlanButton';
import { DescriptionSection } from './DescriptionSection';
import { PricingBreakdownSection } from './PricingBreakdownSection';

const StyledUl = styled.ul`
  margin-left: ${theme.spacing(4)}px;
`;
const StyledP = styled.p`
  font-weight: 700;
  margin-top: ${theme.spacing(1)}px;
  margin-bottom: ${theme.spacing(1)}px;
`;

export const CustomPlansDetailsView: React.FC = () => {
  const id = 'custom-plan-price-details-view';
  const { altId } = useParams();
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [queryParams] = useSearchParams();
  const doneDialog = useConfirmationDialog();
  const isNew = Boolean(queryParams.get('new'));
  const variables = { input: { altId: altId || '', showDeleted: true } };
  const { data, loading: dataLoading } = useGetChargerGroupForExtendQuery({
    variables,
    skip: !altId,
    fetchPolicy: 'cache-and-network',
  });

  const disabled = data?.getChargerGroup ? !!data?.getChargerGroup?.deletedAt : true;

  const loading = !altId || dataLoading;

  const status = data?.getChargerGroup?.status;

  const handleDone = () => {
    if (disabled) {
      navigate('/extend-plus/custom-plans');
      return;
    }
    track(events.customPlanSummary.CLICKED_DONE_BUTTON);
    if (status !== ChargerGroupSyncStatus.Synced) {
      navigate('/extend-plus/custom-plans');
      return;
    }
    doneDialog.open({
      title: isNew ? 'Your custom plan is almost ready!' : 'Custom plan price updates',
      body: isNew
        ? 'Your new custom plan has been created and your EVgo support team will be reviewing it shortly.'
        : 'Your updates will be in effect immediately.',
      hideCancel: true,
      buttonConfirmText: 'CONFIRM',
      body2: () => {
        return isNew ? (
          <>
            <StyledP style={{ fontWeight: 700 }}>What to Expect Next:</StyledP>
            <StyledUl>
              <li>Your EVgo support team will receive the details of this plan.</li>
              <li>
                A new promotion portal will be created, if one does not already exist, where your customers can register
                to enjoy these custom plan prices.
              </li>
              <li>
                Once complete, an EVgo support team member will contact you with a list of promo codes and a portal URL
                you can distribute to your customers.
              </li>
              <li>You will be able to track registrations and promotion success metrics on the Dashboard.</li>
            </StyledUl>
          </>
        ) : (
          <>
            <StyledP style={{ fontWeight: 700 }}>Few things to keep in mind:</StyledP>
            <StyledUl>
              <li>
                Any charging sessions made on the previous plan prices, will not be updated, and these changes will go
                in effect for any new charging session moving forward
              </li>
              <li>All promo codes (existing and newly created) will have the new plan prices applied immediately.</li>
              <li>The same promotional portal can be used</li>
            </StyledUl>
          </>
        );
      },
      callback: () => navigate('/extend-plus/custom-plans'),
    });
  };

  return (
    <PageContent
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-custom-plans-list-link`} to="/extend-plus/custom-plans">
              Custom Plan Prices
            </BreadcrumbLink>
            {!loading && data?.getChargerGroup?.chargerGroupName && (
              <BreadcrumbText data-testid={`${id}-custom-plan-name-text-breadcrumb`}>
                {data?.getChargerGroup?.chargerGroupName}
              </BreadcrumbText>
            )}
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Title>Custom Plan Details</Title>
                {data?.getChargerGroup && (
                  <ChargerGroupSyncStatusComponent
                    data={data?.getChargerGroup}
                    customStyle={{ marginLeft: `${theme.spacing(2)}px` }}
                  />
                )}
                {!loading && disabled && (
                  <Box border="1px solid #ddd" bgcolor="#eee" padding="4px" borderRadius={5} ml={2}>
                    <Typography>Deactivated</Typography>
                  </Box>
                )}
              </Box>
            </>
          }
        ></PageHeader>
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          {isNew && <CustomPlanPriceStepper currentStep={Step.Summary} />}
          {status === ChargerGroupSyncStatus.NotSynced && (
            <ScreenSnackbar severity="error">
              Prices failed to sync. We are aware of the issue and working to resolve it. Please check back later
            </ScreenSnackbar>
          )}

          <Box>
            <DescriptionSection
              chargerGroupName={data?.getChargerGroup?.chargerGroupName}
              description={data?.getChargerGroup?.description || ''}
              planDuration={data?.getChargerGroup?.planDuration}
              disabled={disabled}
            />
            <PricingBreakdownSection disabled={disabled} planTariffs={data?.getChargerGroup?.planTariffs} />

            {altId && <DeactivatePlanButton altId={altId} disabled={disabled} />}
          </Box>

          <PageActions sticky>
            <Button
              data-testid={`${id}-cancel-button`}
              color="secondary"
              onClick={() => {
                navigate('/extend-plus/custom-plans');
                track(events.customPlanSummary.CLICKED_CANCEL_BUTTON);
              }}
            >
              Back
            </Button>
            <Button data-testid={`${id}-save-button`} variant="contained" color="secondary" onClick={handleDone}>
              Done
            </Button>
          </PageActions>
        </Fragment>
      )}
    </PageContent>
  );
};
