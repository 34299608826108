import { ChargerPricing } from 'src/@types';

type FormatError = {
  message: string;
  altId?: ChargerPricing['altId'];
};

export const formatErrorMessage = ({ message, altId }: FormatError) => {
  if (message === 'Database Unique Constraint Error') {
    return 'Sorry, a group with that name has already been created.';
  }

  if (message.includes('caption already in use')) {
    return 'Sorry, this Charger Price name already exists. Please provide a unique name.';
  }

  if (altId && message.includes('404')) {
    return (message =
      'Sorry, we were unable to update your Charger Price because the charger group does not exist on Driivz.');
  }

  return `Sorry, we were unable to ${altId ? 'update' : 'create'} your charger price`;
};
