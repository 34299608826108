import moment from 'moment';
import { CSVLink } from 'react-csv';
import { GetApp } from '@material-ui/icons';
import { useRef, useState } from 'react';
import { Button, CircularProgress, Box } from '@material-ui/core';

import config from 'src/config';
import { useSnackbar } from 'src/lib/hooks';
import { ListTransactionsV2FilterInput, useListTransactionsV2ExtendForExportLazyQuery } from 'src/@types';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { useFormattedTransactionsForExport } from 'src/lib/hooks/useFormattedTransactionsForExport';

type ExportButtonProps = {
  empty: boolean;
  filter: ListTransactionsV2FilterInput;
  disabled?: boolean;
  pageSize?: number;
};

export default function ExportButton({ empty, disabled, filter, pageSize }: ExportButtonProps) {
  const ref = useRef<CSVLink>();
  const snackbar = useSnackbar();
  const [today] = useState(() => moment(new Date()).format('YYYY-MM-DD'));
  const { track } = useAnalytics();
  const filename = `Reports_${today}_session_transactions.csv`;

  const [runQuery, { loading, data }] = useListTransactionsV2ExtendForExportLazyQuery();

  const onClick = async () => {
    if (empty) {
      snackbar.error("There's no data to export.");
      return;
    }

    track(events.reportsView.DOWNLOAD_SESSIONS_CSV);

    await runQuery({
      variables: {
        input: {
          page: 0,
          pageSize: pageSize || config.maxPageSize,
          filter,
        },
      },
    });

    // @ts-ignore
    ref.current?.link?.click();
  };

  const rows = useFormattedTransactionsForExport({ transactions: data?.listTransactionsV2?.edges || [] });

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={onClick}
        disabled={loading || disabled}
        data-testid="download-session-transactions-button"
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : <GetApp />}

        <Box component="span" pl={2}>
          Download CSV
        </Box>
      </Button>

      <CSVLink ref={ref as never} data={rows} filename={filename} />
    </>
  );
}
