import { extendPlusTheme } from 'src/theme/extendPlusTheme';
import { PriceCard, PriceCardProps } from '../PriceCard';
import { TariffCustomPricing } from 'src/@types';
import { weekDays } from '../helpers';
import { useMemo } from 'react';

export type PriceCardGroupProps = TariffCustomPricing & {
  index: number;
  name: string;
};

export const PriceCardGroup = ({ index, name, tariffSchedules, pricing }: PriceCardGroupProps) => {
  const priceCards = useMemo(() => {
    const priceCardsChunkDays: PriceCardProps[] = [];
    const bgcolor = extendPlusTheme.palette.sequential[900 - (index <= 9 ? index * 100 : 50)];
    //@ts-ignore
    const color = extendPlusTheme.palette.grey[100 + (index < 6 ? index * 100 : 900)];

    tariffSchedules?.forEach((schedule) => {
      schedule?.daysOfWeek.forEach((day, indexDay) => {
        const indexDayLessOne = weekDays.indexOf(day) - 1;
        const lessOneIndexDay = weekDays.indexOf(schedule?.daysOfWeek[indexDay - 1]);

        if (priceCardsChunkDays.length && indexDayLessOne === lessOneIndexDay) {
          //Add to already created chunk os days
          priceCardsChunkDays[priceCardsChunkDays.length - 1].tariffSchedules.daysOfWeek.push(day);
        } else {
          //Add a new chunk of days
          priceCardsChunkDays.push({
            color,
            bgcolor,
            name,
            tariffSchedules: { ...schedule, daysOfWeek: [day] },
            pricing: pricing || {},
          });
        }
      });
    });

    return priceCardsChunkDays;
  }, [index, name, tariffSchedules, pricing]);

  return (
    <div>
      {priceCards.map((priceCard, indexCard) => (
        <PriceCard key={`price-group-${index}-card-${indexCard}`} {...priceCard}></PriceCard>
      ))}
    </div>
  );
};
