import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box, Typography, colors, useTheme, Theme } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { theme } from '@evgo/react-material-components';

import { TableLink } from 'src/components/shared/TableLink';
import { Column, Table } from 'src/components/shared/Table';
import { Card, CardHeader } from 'src/components/shared/Card';
import {
  ListSitesConsumptionForExtendData,
  SessionDatePreset,
  useListSitesConsumptionForExtendQuery,
} from 'src/@types';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { formatNumber } from 'src/lib/helpers/formatNumber';

type TopSiteListProps = {
  siteIds?: string[];
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

const StyledTypography = styled(Typography)`
  color: ${colors.green[600]};
`;

function SiteName(props: { data: ListSitesConsumptionForExtendData }) {
  const { track } = useAnalytics();
  const { siteId, siteName } = props.data;
  return (
    <TableLink
      to={`/extend-plus/sites/${siteId}`}
      onClick={() => {
        track(events.dashboardView.CLICKED_SITE_DETAIL);
      }}
    >
      {siteName}
    </TableLink>
  );
}

const columns: Column<ListSitesConsumptionForExtendData>[] = [
  {
    key: 'siteName',
    label: 'Site',
    component: SiteName,
    sortable: false,
    width: '52%',
  },
  {
    key: 'totalSessionsCost',
    label: 'Total income',
    component: ({ data: { totalSessionsCost } }) => (
      <Box display="flex" flexDirection="row" gridColumnGap={4}>
        <StyledTypography variant="body2">{`$${formatNumber(totalSessionsCost)}`}</StyledTypography>
      </Box>
    ),
    sortable: false,
    width: '12%',
  },
  {
    key: 'totalSessionsPower',
    label: 'Total kWh',
    format: (value) => formatNumber(Number(value as unknown as number)),
    sortable: false,
    width: '12%',
  },
];

export function TopSiteList({ siteIds }: TopSiteListProps) {
  const { track } = useAnalytics();
  const { data, loading, error } = useListSitesConsumptionForExtendQuery({
    variables: {
      input: {
        filter: {
          datePreset: SessionDatePreset.Last_7d,
          siteIds: siteIds,
        },
        page: 0,
        pageSize: 3,
      },
    },
    skip: !siteIds?.length,
  });
  const edges = data?.listSitesConsumptionForExtend?.edges || [];

  const extendTheme: Theme = useTheme();

  return (
    <StyledCard data-testid="top-sites">
      <CardHeader
        subtitle={
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1" color="textSecondary">
              Top Sites This Week
            </Typography>
            <Link
              data-testid="all-sites"
              to="/extend-plus/sites"
              onClick={() => {
                track(events.dashboardView.CLICKED_VIEW_ALL_SITES);
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="subtitle2" color="secondary">
                  View all sites
                </Typography>
                <Box fontSize={extendTheme.typography.subtitle2.fontSize} pl="2px" display="flex">
                  <ArrowForward fontSize="inherit" color="secondary" />
                </Box>
              </Box>
            </Link>
          </Box>
        }
      />
      <div style={{ overflowX: 'scroll' }}>
        <div style={{ minWidth: '225px' }}>
          <Table columns={columns} data={edges} loading={loading} error={error} data-testid="top-sites-table" />
        </div>
      </div>
    </StyledCard>
  );
}
