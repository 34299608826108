import { useMemo } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { theme } from '@evgo/react-material-components';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';
import { Tooltip } from 'src/components/shared/Tooltip';
import { PieChart } from 'src/components/shared/PieChart';
import { Card, CardHeader } from 'src/components/shared/Card';
import config from 'src/config';
import { SessionSummaryByPlan, useGetCustomPlanBreakdownQuery, useListChargerGroupsForExtendQuery } from 'src/@types';

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  color: ${theme.palette.secondary[800]};

  :hover {
    text-decoration: underline;
  }

  svg {
    margin-top: ${theme.spacing(0.75)}px;
    margin-left: ${theme.spacing(0.5)}px;
  }
`;

const testid = (value: string) => ({ 'data-testid': `custom-plan-income-${value}` });

function OutOfNetworkPlanLabel() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      Public Pricing
      <Box display="flex" justifyContent="center" alignItems="center" fontSize={theme.typography.fontSize}>
        <Tooltip
          fontSize="inherit"
          content="This includes all sessions that applied a base charger price that is available to the public. Compare how your custom plans perform against the public charger prices."
        />
      </Box>
    </Box>
  );
}

export function CustomPlanBreakdownChart({ timePeriod }: { timePeriod: number }) {
  const { data, loading } = useGetCustomPlanBreakdownQuery({
    variables: {
      input: {
        startDate: moment().subtract(timePeriod, 'days').startOf('day').format('YYYY-MM-DD'),
      },
    },
  });

  const { data: planData, loading: planLoading } = useListChargerGroupsForExtendQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
        filter: {
          chargerGroupTypeId: {
            eq: 402,
          },
        },
        showDeleted: false,
      },
    },
  });

  const publicPlanName = 'OUT-OF-NETWORK PLAN';

  const getChartData = (summaryData: SessionSummaryByPlan[]) => {
    const colors = Object.values(extendPlusTheme.palette.categorical);
    const totalCost = summaryData?.reduce((acc, cv) => (acc += cv.powerCostValue), 0);
    return summaryData?.map((row, i) => {
      return {
        ...row,
        value: totalCost ? Math.round((row.powerCostValue / totalCost) * 100) : 0,
        cost: row.powerCostValue,
        label: row.planName != publicPlanName ? row.planName : <OutOfNetworkPlanLabel />,
        color: colors[i],
      };
    });
  };

  const chartData = useMemo(() => getChartData(data?.getSessionSummaryByPlan || []), [data?.getSessionSummaryByPlan]);
  const customPlanPercent = useMemo(
    () =>
      Math.round(
        chartData.reduce((acc, cv) => {
          const value = cv.planName != publicPlanName ? cv.value : 0;
          return (acc += value);
        }, 0),
      ),
    [chartData],
  );

  const percentToDisplay = customPlanPercent >= 1 ? customPlanPercent : Boolean(customPlanPercent) ? '<1' : 0;

  return (
    <StyledCard {...testid('card')}>
      <CardHeader
        title="Custom Plan Breakdown"
        subtitle={
          <Box display="flex" flexDirection="column">
            Session income from custom plans
            <StyledLink {...testid('all-plans-link')} to="/extend-plus/custom-plans">
              View all plans <ArrowForward fontSize="inherit" />
            </StyledLink>
          </Box>
        }
      />

      {loading || planLoading ? (
        <Box display="flex" justifyContent="center" {...testid('loading')}>
          <CircularProgress />
        </Box>
      ) : !planData?.listChargerGroups?.edges?.length ? (
        <Box
          {...testid('chart-no-custom-plan')}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="372px"
          width="100%"
        >
          <Box>
            <Typography variant="subtitle2">You have no custom plans.</Typography>
            <Typography variant="subtitle2">
              Create a{' '}
              <StyledLink {...testid('all-plans-chart-link')} to="/extend-plus/custom-plans">
                custom plan here
              </StyledLink>
              .
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" height="372px">
          <Box {...testid('chart-legends')} display="flex" marginTop={theme.spacing(1 / 3)}>
            {chartData?.map((row) =>
              row.planName !== publicPlanName ? (
                <Box
                  paddingLeft={theme.spacing(1 / 3)}
                  marginRight={theme.spacing(1 / 2)}
                  borderLeft={1}
                  borderColor={theme.palette.grey[300]}
                >
                  <Box bgcolor={row?.color || '#ba9730'} width={theme.spacing(2)} height={theme.spacing(2)} />
                  <Typography variant="h6">$ {row.cost.toFixed(2)}</Typography>
                </Box>
              ) : (
                <></>
              ),
            )}
          </Box>

          <PieChart
            key={testid('chart')['data-testid']}
            id={testid('chart')['data-testid']}
            {...testid('chart')}
            className={testid('chart')['data-testid']}
            data={chartData}
            height={300}
            valueFormat={(v) => `${v}%`}
            caption={
              <Box display="flex" flexDirection="column" alignItems="center" data-testid="custom-plan-percent">
                <Typography variant="subtitle2">{percentToDisplay}%</Typography>
                <Typography variant="caption">custom plans</Typography>
              </Box>
            }
          />
        </Box>
      )}
    </StyledCard>
  );
}
