import { gql } from '@apollo/client';

export const listChargersV2Connectors = gql`
  query listChargersV2Connectors($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
      edges {
        evses {
          connectors {
            connectorStatus {
              columnText
            }
          }
        }
      }
    }
  }
`;
