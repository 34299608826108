import { useQuery } from '@apollo/client';
import { Box, Button } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ChargerGroup,
  TariffSyncStatus as TariffForExtendSyncStatusType,
  TariffCustomPricing,
  ChargerPricing,
} from 'src/@types';
import { getTariffForExtend as GET_CHARGER_PRICE_QUERY } from 'src/apollo/queries/extendPlus';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { PageActions } from 'src/components/shared/PageActions';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import ScreenSnackbar from 'src/components/shared/ScreenSnackbar';
import { Title } from 'src/components/shared/Title';
import { theme } from '@evgo/react-material-components';
import {
  transformTariffCustomPricingForExtend,
  transformTariffDefaultPricingForExtend,
} from 'src/lib/helpers/transformTariffPricingForExtend';
import TariffForExtendSyncStatus from 'src/components/shared/TariffSyncStatus';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { ChargerPriceStepper, Step } from '../ChargerPriceStepper';
import { DefaultPriceSection } from './DefaultPriceSection';
import { ChargerPriceSection } from './ChargerPriceSection';
import { TimeSpecificPriceSection } from './TimeSpecificPriceSection';
import { transformReservationCustomPricingForExtend } from 'src/lib/helpers/transformReservationPricingForExtend';
import { ReservationPriceSection } from './ReservationPriceSection';
import { Subtitle } from 'src/components/shared/MultiSelectWithChipsInput';
import { DeleteChargerPriceButton } from './DeleteChargerPriceButton';

export const ChargerPricesDetailsView: React.FC = () => {
  const id = 'charger-price-details-view';
  const navigate = useNavigate();
  const params = useParams();
  const [queryParams] = useSearchParams();
  const { track } = useAnalytics();
  const isNew = Boolean(queryParams.get('new'));

  const variables = {
    input: { altId: params.altId || '' },
  };

  // TODO: switch to use useGetTariffForExtendQuery
  // once we are using the tariff resync instead of charger group below
  const { data, loading } = useQuery(GET_CHARGER_PRICE_QUERY, {
    variables,
    skip: !params.altId,
    fetchPolicy: 'cache-and-network',
  });

  const [status, setStatus] = useState(data?.getTariff?.status);
  const chargerGroups = (data?.getTariff.chargerGroups || []).map((group: ChargerGroup) => group);

  useEffect(() => {
    if (status !== data?.getTariff?.status) {
      setStatus(data?.getTariff?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getTariff?.status]);

  return (
    <PageContent
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-charger-price-list-link`} to="/extend-plus/charger-prices">
              Charger Prices
            </BreadcrumbLink>
            {data?.getTariff?.tariffName && (
              <BreadcrumbText data-testid={`${id}-charger-price-group-breadcrumb`}>
                {data?.getTariff?.tariffName}
              </BreadcrumbText>
            )}
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mt={4}>
                <Title>Charger Price Details</Title>
                <Subtitle>Manage and apply pricing for your charger groups.</Subtitle>
              </Box>
              {data?.getTariff && !isNew && (
                <TariffForExtendSyncStatus
                  data={{ status } as ChargerPricing}
                  customStyle={{ marginLeft: `${theme.spacing(2)}px` }}
                />
              )}
            </Box>
          }
        />
      }
      loading={loading || !params.altId}
    >
      <Fragment>
        {isNew && <ChargerPriceStepper currentStep={Step.Summary} />}
        {status === TariffForExtendSyncStatusType.NotSynced && !isNew && (
          <ScreenSnackbar severity="error">
            Prices failed to sync. We are aware of the issue and working to resolve it. Please check back later.
          </ScreenSnackbar>
        )}

        <Box mb={8}>
          <ChargerPriceSection
            tariffName={data?.getTariff?.tariffName}
            tariffDisplayName={data?.getTariff?.tariffDisplayName}
            chargerGroups={chargerGroups}
          />
          <DefaultPriceSection pricing={transformTariffDefaultPricingForExtend(data?.getTariff?.defaultPricing)} />
          <TimeSpecificPriceSection
            customPricing={data?.getTariff?.customPricing?.map((customPricing: TariffCustomPricing) =>
              transformTariffCustomPricingForExtend(customPricing),
            )}
          />
          <ReservationPriceSection
            pricing={transformReservationCustomPricingForExtend(data?.getTariff?.reservationTerm)}
          />
          {!isNew && <DeleteChargerPriceButton altId={params.altId || ''} />}
        </Box>

        <PageActions sticky>
          <Button
            color="secondary"
            data-testid={`${id}-back-btn`}
            onClick={() => navigate(`/extend-plus/charger-prices/${isNew ? `${params.altId}/pricing?new=true` : ''}`)}
          >
            Back
          </Button>

          <Button
            size="large"
            variant="contained"
            color="secondary"
            data-testid={`${id}-done-btn`}
            onClick={() => {
              navigate('/extend-plus/charger-prices');
              track(events.chargerPriceSummary.CLICKED_DONE_BUTTON);
            }}
          >
            Done
          </Button>
        </PageActions>
      </Fragment>
    </PageContent>
  );
};
