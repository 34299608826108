import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import { theme } from '@evgo/react-material-components';
import { useDebounceFn } from 'ahooks';

import { Card, CardHeader } from 'src/components/shared/Card';
import { SearchField } from 'src/components/shared/SearchField';
import {
  ListTransactionsV2FilterInput,
  TransactionV2,
  ListTransactionsV2Input,
  useListTransactionsV2ExtendQuery,
} from 'src/@types';
import { Column, Direction, Table, usePagination } from 'src/components/shared/Table';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

import ExportButton from './ExportButton';
import { transactionItemColumns, transactionColumnsByKey } from '../../shared/TransactionTable';
import { camelToSnakeCase } from 'src/lib/helpers/camelToSnakeCase';
import { BasicTable } from '../../shared/BasicTable';
import { ListTransactionsV2TransactionItem } from 'src/@types/shared';

type SessionsTransactionsListProps = {
  siteIds?: string[];
  chargerGroupIds?: string[];
  planIds?: string[];
  transactionTypeIds?: string[];
  startDate: Date;
  endDate: Date;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

const columns: Column<TransactionV2>[] = [
  transactionColumnsByKey.vendorId,
  transactionColumnsByKey.dateTime,
  transactionColumnsByKey['type.columnText'],
  transactionColumnsByKey['session.connector.evse.charger.site.siteName'],
  transactionColumnsByKey.chargerAndConnector,
  transactionColumnsByKey['session.startSource.columnText'],
  transactionColumnsByKey['session.powerValue'],
  transactionColumnsByKey['session.sessionDuration'],
  transactionColumnsByKey['session.plan.chargerGroup.chargerGroupName'],
  transactionColumnsByKey.taxRate,
  transactionColumnsByKey.tax,
  transactionColumnsByKey.calculatedAmount,
];

export function TransactionsList({
  siteIds,
  chargerGroupIds,
  planIds,
  transactionTypeIds,
  startDate,
  endDate,
}: SessionsTransactionsListProps) {
  const [transactionItems, setTransactionItems] = useState<ListTransactionsV2TransactionItem[]>([]);
  const [tableSearch, setTableSearch] = useState<string>('');
  const pagination = usePagination();
  const [tableSortBy, setTableSortBy] = useState<string>('');
  const [tableSortDirection, setTableSortDirection] = useState<Direction>(Direction.Desc);
  const { track } = useAnalytics();

  useEffect(() => {
    pagination.onPageChange(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIds, chargerGroupIds, planIds, transactionTypeIds, startDate, endDate]);

  const filter: ListTransactionsV2FilterInput = {
    // @ts-ignore the date will be converted to a string in the request
    startTimeLocal: { between: [startDate, endDate] },
    siteId: siteIds?.length === 0 ? undefined : { in: siteIds },
    chargerGroupId: chargerGroupIds?.length === 0 ? undefined : { in: chargerGroupIds },
    planId: planIds?.length === 0 ? undefined : { in: planIds },
    transactionTypeId: transactionTypeIds?.length === 0 ? undefined : { in: transactionTypeIds },
  };

  const input: ListTransactionsV2Input = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    filter,
    search: {
      siteName: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      chargerName: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
      vendorId: !tableSearch ? undefined : { eq: Number(tableSearch) },
      planName: !tableSearch ? undefined : { beginsWith: `%${tableSearch}%` },
    },
    // @ts-ignore it would be pretty difficult to type this. TODO: consider seperating the sortBy and direction into different fields.
    sort: tableSortBy ? `${camelToSnakeCase(tableSortBy)}_${tableSortDirection}` : 'ID_DESC',
  };

  const { data, loading, error } = useListTransactionsV2ExtendQuery({
    variables: {
      input,
    },
    fetchPolicy: 'network-only',
  });

  const { run: debouncedOnSearch } = useDebounceFn(
    (value) => {
      setTableSearch(value);
      pagination.onPageChange(0);
      track(events.reportsView.SEARCHED_SESSIONS_LIST);
    },
    { wait: 1000 },
  );

  const edges = data?.listTransactionsV2?.edges || [];
  const total = data?.listTransactionsV2?.total || 0;

  const getTransactionItemsForTransaction = useCallback(
    ({ altId }: { altId: unknown }) => {
      const transactions = data?.listTransactionsV2?.edges || [];
      const items = transactions.find((tran) => tran?.altId === altId)?.items || [];
      setTransactionItems(items);
    },
    [data],
  );

  return (
    <StyledCard data-testid="session-transactions-card">
      <CardHeader title="Transactions" />

      <Box mb={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Box>
          <SearchField
            onChange={(value: string) => {
              if (value.length === 0 || value.length >= 2) {
                debouncedOnSearch(value);
              }
            }}
            placeholder="Search ID, site, charger, etc."
          />
        </Box>
        <Box pl={2}>
          <ExportButton filter={filter} empty={!total} pageSize={total} />
        </Box>
      </Box>

      <Table
        columns={columns}
        width={`${theme.spacing(200)}px`}
        overflow="auto"
        data={edges}
        loading={loading}
        error={error}
        data-testid="reports-transactions-table"
        pagination={{ ...pagination, total }}
        sorting={{
          sortBy: tableSortBy,
          sortDirection: tableSortDirection,
          onSortByChange: setTableSortBy,
          onSortDirectionChange: setTableSortDirection,
        }}
        onCollapseClick={(row) => getTransactionItemsForTransaction({ altId: row?.altId })}
      >
        <BasicTable
          label="transactions subtable"
          className="transactions-subtable"
          columns={transactionItemColumns}
          data={transactionItems}
          loading={false}
        />
      </Table>
    </StyledCard>
  );
}
