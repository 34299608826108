import _ from 'lodash';
import React from 'react';
import {
  ChargerGroup,
  useListChargerGroupsForExtendQuery,
  useListChargerGroupsPlanTariffTagsForExtendQuery,
  useListChargerGroupsTariffsForExtendQuery,
} from 'src/@types';
import { ChargerGroupTypeId } from 'src/@types/shared';
import ChargerGroupPriceType from 'src/components/shared/ChargerGroupPriceType';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import config from 'src/config';
import { formatDate } from 'src/lib/helpers/formatDate';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { State, ChargerGroupTypes } from '../filterHelpers';
import { getPlanTariffTagsForChargerGroup, getTariffsForChargerGroup } from './utils';

type ColumnDataProps = {
  data: ChargerGroup;
};

type ChargerPriceGroupListProps = {
  search?: string;
  filter?: State['filter'];
};

const ChargerGroupName: React.FC<ColumnDataProps> = ({ data }) => {
  const { track } = useAnalytics();
  const to = `/extend-plus/charger-groups/${data?.altId}/details`;
  return (
    <TableLink to={to} onClick={() => track(events.chargerPricesList.CLICKED_CHARGER_PRICE_LINK)}>
      {data.chargerGroupName}
    </TableLink>
  );
};

const columns: Column[] = [
  { key: 'chargerGroupName', label: 'Name', sortable: true, width: '33%', component: ChargerGroupName },
  { key: 'description', label: 'Description', sortable: true, width: '33%' },
  { key: 'chargers.total', label: '# of Chargers', sortable: true, numeric: true, width: '10%' },
  {
    key: 'createdAt',
    label: 'Create Date',
    sortable: true,
    width: '20%',
    format: (value) => formatDate(`${value}`),
  },
  {
    key: 'chargerGroupPriceType',
    label: 'Price Type',
    sortable: false,
    width: '20%',
    component: ChargerGroupPriceType,
  },
];

export const ChargerGroupsList: React.FC<ChargerPriceGroupListProps> = ({ search, filter }) => {
  const input = {
    pageSize: config.maxPageSize,
    filter: {
      chargerGroupTypeId: {
        eq: ChargerGroupTypeId.ChargerGroups,
      },
    },
  };
  // TODO: consolidate these 3 queries into 1 single purpose query.
  const { data, loading, error } = useListChargerGroupsForExtendQuery({
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: planTariffTagsData } = useListChargerGroupsPlanTariffTagsForExtendQuery({
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: tariffsData } = useListChargerGroupsTariffsForExtendQuery({
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
  });

  const edges = data?.listChargerGroups?.edges || [];
  const chargerGroupsWithPlanTariffTags = planTariffTagsData?.listChargerGroups?.edges || [];
  const chargerGroupsWithTariffs = tariffsData?.listChargerGroups?.edges || [];

  let chargerGroupsWithPlanTariffsAndTariffs = edges.map((chargerGroup) => {
    const planTariffTags = getPlanTariffTagsForChargerGroup(chargerGroupsWithPlanTariffTags, chargerGroup);
    const tariff = getTariffsForChargerGroup(chargerGroupsWithTariffs, chargerGroup);
    return { ...chargerGroup, planTariffTags: planTariffTags || [], tariff: tariff };
  });

  if (search) {
    const regex = new RegExp(_.escapeRegExp(search), 'gi');
    chargerGroupsWithPlanTariffsAndTariffs = chargerGroupsWithPlanTariffsAndTariffs.filter(
      ({ chargerGroupName, description }) => {
        return chargerGroupName?.match(regex) || description?.match(regex);
      },
    );
  }
  if (filter?.chargerGroupTypes.length) {
    const { chargerGroupTypes } = filter;
    chargerGroupsWithPlanTariffsAndTariffs = chargerGroupsWithPlanTariffsAndTariffs.filter((chargerGroup) => {
      const { planTariffTags, tariff } = chargerGroup;
      const chargerGroupPlans = planTariffTags?.map((tag) => tag?.planChargerGroup).filter((plan) => !!plan);

      return (
        (chargerGroupPlans?.length && chargerGroupTypes.includes(ChargerGroupTypes.CustomPlans)) ||
        (Boolean(tariff?.reservationTerm) && chargerGroupTypes.includes(ChargerGroupTypes.ReservationFees)) ||
        (Boolean(tariff) && chargerGroupTypes.includes(ChargerGroupTypes.ChargerPrice))
      );
    });
  }

  return (
    <>
      <Table
        id="charger-groups"
        data-testid="charger-groups-table"
        columns={columns}
        data={chargerGroupsWithPlanTariffsAndTariffs}
        loading={loading}
        error={error}
        sorting={{
          sortBy: 'chargerGroupName',
          sortDirection: Direction.Asc,
        }}
        noDataMessage="Create a price group to easily apply prices to your chargers"
      />
    </>
  );
};
