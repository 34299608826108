import { ArrowForward, Check } from '@material-ui/icons';
import { MetricCard, MetricCardPlaceholder } from 'src/components/shared/MetricCard';
import { theme } from '@evgo/react-material-components';
import { Link } from 'react-router-dom';
import { useListChargersV2ShallowQuery } from 'src/@types';
import { colors } from '@material-ui/core';
import { LabelDiv } from '../styles';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import config from 'src/config';

export const UnassignedChargersCard = () => {
  const { track } = useAnalytics();

  const unassignedChargersInput = {
    pageSize: config.maxPageSize,
    filter: {
      chargerGroupName: { eq: null },
    },
  };

  const { data: unassignedChargersData, loading: unassignedChargersLoading } = useListChargersV2ShallowQuery({
    variables: {
      input: unassignedChargersInput,
    },
    fetchPolicy: 'network-only',
  });

  const unassignedChargersCount = unassignedChargersData?.listChargersV2?.total || 0;
  const loading = unassignedChargersLoading;
  const hasUnassignedChargers = unassignedChargersCount > 0;
  const primaryTextColor = hasUnassignedChargers ? colors.red[600] : theme.palette.text.primary;
  const labelTextColor = hasUnassignedChargers ? theme.palette.text.primary : colors.green[600];

  const UnassignedMetricLabel = hasUnassignedChargers ? (
    <Link
      to={`/extend-plus/charger-groups`}
      onClick={() => {
        track(events.dashboardView.CLICKED_ASSIGN_GROUP);
      }}
    >
      <LabelDiv>
        <span>ASSIGN A GROUP</span>
        <ArrowForward />
      </LabelDiv>
    </Link>
  ) : (
    <LabelDiv>
      <Check />
      <span>ALL CHARGERS HAVE A GROUP</span>
    </LabelDiv>
  );

  return (
    <>
      {loading ? (
        <MetricCardPlaceholder data-testid="metricCard-unassigned-chargers-loading" />
      ) : (
        <MetricCard
          className="metric-card"
          primaryText={unassignedChargersCount}
          theming={{ primaryTextColor, labelTextColor }}
          secondaryText="Unassigned"
          label={UnassignedMetricLabel}
          data-testid="metricCard-unassigned-chargers"
        />
      )}
    </>
  );
};
