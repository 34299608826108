import { useMemo } from 'react';
import { useGetExtendCalculatedHostPlansQuery, Maybe } from 'src/@types';
import { ListTransactionsV2ExtendForExportTransaction } from 'src/@types/shared';
import {
  formatTransactionForExport,
  FormattedTransactionForExport,
} from 'src/components/views/ExtendPlusView/shared/TransactionTable';

type Props = {
  transactions: Maybe<ListTransactionsV2ExtendForExportTransaction>[];
};

export function useFormattedTransactionsForExport({ transactions }: Props) {
  const { data: calculatedPlans } = useGetExtendCalculatedHostPlansQuery({ variables: {} });

  const rows = useMemo<FormattedTransactionForExport[]>(
    () =>
      (transactions || []).map((edge) => {
        const row = formatTransactionForExport(edge);
        const hasCalculatedPlans = calculatedPlans?.getExtendCalculatedHostPlans.length;
        if (!hasCalculatedPlans) delete row['eXtend Calculated Transaction'];
        return row;
      }),
    [transactions, calculatedPlans],
  );

  return rows;
}
