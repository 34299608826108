import { ApolloError } from '@apollo/client';
import { theme } from '@evgo/react-material-components';
import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ChargerGroup, Maybe } from 'src/@types';
import ChargerGroupSyncStatus from 'src/components/shared/ChargerGroupSyncStatus';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';

type Props = {
  data: Maybe<Partial<ChargerGroup>>[];
  loading: boolean;
  error?: ApolloError;
};

type ColumnDataProps = {
  data: ChargerGroup;
};

const ChargerGroupName: React.FC<ColumnDataProps> = ({ data }) => {
  const { track } = useAnalytics();
  const to = `/extend-plus/custom-plans/${data?.altId}/details`;
  return (
    <TableLink
      style={{ color: data.deletedAt ? theme.palette.grey['400'] : undefined }}
      onClick={() => track(events.customPlanList.CLICKED_CUSTOM_PLAN_PRICE_LINK)}
      to={to}
    >
      {data.chargerGroupName}
    </TableLink>
  );
};

const ColumnWrapper = styled.span<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? theme.palette.grey['400'] : 'inherit')};
`;

const CGDescriptionColumn: React.FC<ColumnDataProps> = ({ data }) => (
  <ColumnWrapper disabled={!!data.deletedAt}>{data.description}</ColumnWrapper>
);

const EditCustomPlan: React.FC<ColumnDataProps> = ({ data }) => {
  return (
    <Link to={`/extend-plus/custom-plans/${data?.altId}/details`}>
      <Button data-testid="edit-custom-plan" color="secondary" variant="outlined">
        Edit
      </Button>
    </Link>
  );
};

const columns: Column[] = [
  { key: 'chargerGroupName', label: 'Plan Name', sortable: true, width: '33%', component: ChargerGroupName },
  { key: 'description', label: 'Description', sortable: true, width: '33%', component: CGDescriptionColumn },
  {
    key: 'status',
    label: 'Sync Status',
    sortable: false,
    width: '20%',
    component: ChargerGroupSyncStatus,
  },
  { key: 'remove', label: '', sortable: false, width: '10%', align: 'right', component: EditCustomPlan },
];

export const CustomPlanPriceGroupsList: React.FC<Props> = ({ data, loading, error }) => {
  return (
    <Table
      id="charger-groups-table"
      data-testid="charger-groups-table"
      columns={columns}
      data={data}
      loading={loading}
      error={error}
      sorting={{
        sortBy: 'chargerGroupName',
        sortDirection: Direction.Asc,
      }}
      noDataMessage="Create a plan to provide custom plan prices and access to your customers"
    />
  );
};
