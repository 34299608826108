import styled from 'styled-components';
import { useReducer, useState } from 'react';
import { theme } from '@evgo/react-material-components';

import { Card, CardHeader } from 'src/components/shared/Card';
import {
  EventLog,
  FalconConstraint,
  ListEventLogFilterInput,
  ListEventLogInput,
  ChargerPricing,
  User,
} from 'src/@types';
import { Column, Direction, Table, usePagination } from 'src/components/shared/Table';

import { camelToSnakeCase } from 'src/lib/helpers/camelToSnakeCase';
import config from 'src/config';
import { useQuery } from '@apollo/client';
import { listChargerPricesEventLog, listExtendUsersForHost, listTariffsForExtend } from 'src/apollo/queries/extendPlus';
import { formatPrice } from '../../ChargerPrices/Details/helpers';
import { Box } from '@material-ui/core';
import { reducer, initialState } from './Filters/filterHelpers';
import { EventLogFilter } from './Filters';
import { listFalconConstraints } from 'src/apollo/queries/options';
import ExportButton from './ExportButton';
import { formatDate } from 'src/lib/helpers/formatDate';
import { formatTime } from 'src/lib/helpers/formatTime';
import { formatTimeZoneAbbreviation, userLocalTimeZone } from 'src/lib/helpers/formatTimeZoneAbbreviation';
import { useUTCDateRangeValue } from '../utils';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';

type ColumnDataProps = {
  data: EventLog;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

const PreviousValue: React.FC<ColumnDataProps> = ({ data }) => {
  if (data.jsonData?.tariffName) return <span>{data.jsonData?.tariffName.before || '-'}</span>;
  if (data.jsonData?.chargerGroups) return <span>{data.jsonData?.chargerGroups.before.join(', ') || '-'}</span>;
  const previousPrice = data?.jsonData?.defaultPricing?.before || {};
  const perKwh =
    previousPrice?.perKwh || previousPrice?.perKwh === 0 ? formatPrice(parseFloat(previousPrice?.perKwh)) : null;
  const perMinute =
    previousPrice?.perMinute || previousPrice?.perMinute === 0
      ? formatPrice(parseFloat(previousPrice?.perMinute))
      : null;
  const perSession =
    previousPrice?.perSession || previousPrice?.perSession === 0
      ? formatPrice(parseFloat(previousPrice?.perSession))
      : null;

  if (previousPrice && (perKwh || perMinute || perSession)) {
    return (
      <span>
        {perKwh && (
          <>
            ${perKwh} per kWh <br />
          </>
        )}
        {perMinute && (
          <>
            ${perMinute} per min
            <br />
          </>
        )}
        {perSession && <>${perSession} per session</>}
      </span>
    );
  } else {
    return <span>-</span>;
  }
};

const columns: Column[] = [
  {
    key: 'entityData.tariffName',
    label: 'Charger Price',
    sortable: true,
    width: '10%',
    sortName: 'tariffName',
  },
  {
    key: 'eventDate',
    label: 'Date/Time',
    sortable: true,
    width: '15%',
    formatWithRowData: ({ eventDate }) => {
      const timeZone = userLocalTimeZone;
      const localDate = formatDate(eventDate, 'L', timeZone);
      const localTime = formatTime(eventDate, 'LT', timeZone);
      const localTz = formatTimeZoneAbbreviation();
      return `${localDate} ${localTime} ${localTz}`;
    },
  },
  {
    key: 'eventType.columnText',
    label: 'Event Type',
    sortable: true,
    width: '15%',
    sortName: 'eventType',
    formatWithRowData: ({ eventType }) => {
      if (eventType.id == 625) return 'Charger Groups Updated';
      return eventType.columnText;
    },
  },
  {
    key: 'eventText',
    label: 'Event Details',
    sortable: false,
    width: '15%',
  },
  {
    key: 'prevValue',
    label: 'Previous Value',
    sortable: false,
    width: '15%',
    component: PreviousValue,
  },
  {
    key: 'user.username',
    label: 'User',
    sortable: true,
    width: '15%',
    sortName: 'username',
  },
];

export function EventLogList() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [tableSortBy, setTableSortBy] = useState<string>('eventDate');
  const [tableSortDirection, setTableSortDirection] = useState<Direction>(Direction.Desc);
  const pagination = usePagination();
  const dateRange = useUTCDateRangeValue(state.filter.dateRange);

  useQuery(listExtendUsersForHost, {
    variables: {
      input: {
        page: 0,
        pageSize: config.maxPageSize,
        showDeleted: true,
      },
    },
    onCompleted: (users) => {
      dispatch({
        type: 'setInitialUsers',
        usernames: users.listExtendUsersForHost.edges.map((user: User) => ({
          username: user.username,
        })),
      });
    },
  });

  useQuery(listFalconConstraints, {
    variables: {
      optionsInput: {
        filter: {
          tableName: { eq: 'event_log' },
          columnName: { eq: 'event_type' },
        },
      },
    },
    onCompleted: (eventTypes) => {
      dispatch({
        type: 'setInitialEventTypes',
        eventTypes: eventTypes.listFalconConstraints.edges.map((eventType: FalconConstraint) => ({
          eventName: eventType.id === '625' ? 'Charger Groups Updated' : eventType.columnText,
          value: eventType.id,
        })),
      });
    },
  });

  useQuery(listTariffsForExtend, {
    variables: {
      input: {
        page: 0,
        pageSize: config.maxPageSize,
      },
    },
    onCompleted: (tariffs) => {
      dispatch({
        type: 'setInitialChargerPriceGroups',
        tariffs: tariffs.listTariffs.edges.map((tariff: ChargerPricing) => ({
          id: tariff.id,
          tariffName: tariff.tariffName,
        })),
      });
    },
  });

  const filter: ListEventLogFilterInput = {
    entityType: {
      eq: 'Charger Price',
    },
    eventTypeId: state.filter.eventTypes.length
      ? { in: state.filter.eventTypes.map((id: string) => Number(id)) }
      : undefined,
    username: state.filter.usernames.length ? { in: state.filter.usernames } : undefined,
    entityId: state.filter.entityIds.length
      ? { in: state.filter.entityIds.map((id: string) => Number(id)) }
      : undefined,
    // @ts-ignore the date will be converted to a string in the request
    eventDate: dateRange ? { between: [dateRange.startDate, dateRange.endDate] } : undefined,
  };

  const input: ListEventLogInput = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    filter,
    // @ts-ignore it would be pretty difficult to type this. TODO: consider seperating the sortBy and direction into different fields.
    sort: `${camelToSnakeCase(tableSortBy)}_${tableSortDirection}`,
  };

  const { data, loading, error } = useQuery(listChargerPricesEventLog, {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
  });

  const edges = data?.listEventLog?.edges || [];
  const total = data?.listEventLog?.total || 0;

  return (
    <PageContent pageHeader={<PageHeader childrenLeft={<Title>Charger Prices Event Log</Title>} />}>
      <Box>
        <EventLogFilter state={state} dispatch={dispatch} resetPagination={() => pagination.onPageChange(0)} />
      </Box>
      <StyledCard data-testid="event-log-card">
        <CardHeader title="Charger Price Event Log" />

        <Box mb={3} display="flex" justifyContent="flex-end" alignItems="center">
          <Box pl={2}>
            <ExportButton
              filter={filter}
              empty={!total}
              pageSize={total}
              sort={`${camelToSnakeCase(tableSortBy)}_${tableSortDirection}`}
            />
          </Box>
        </Box>

        <Table
          columns={columns}
          width={`${theme.spacing(200)}px`}
          overflow="auto"
          data={edges}
          loading={loading}
          error={error}
          id="charger-price-event-log-table"
          data-testid="charger-price-event-log-table"
          pagination={{ ...pagination, total }}
          sorting={{
            sortBy: tableSortBy,
            sortDirection: tableSortDirection,
            onSortByChange: setTableSortBy,
            onSortDirectionChange: setTableSortDirection,
          }}
        />
      </StyledCard>
    </PageContent>
  );
}
