import { Chip, Tooltip } from '@material-ui/core';
import {
  ErrorOutline as ErrorOutlineIcon,
  Check as CheckIcon,
  EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons';
import React from 'react';
import { ChargerPricing, TariffSyncStatus } from 'src/@types';

type TariffForExtendSyncStatusProps = {
  data: ChargerPricing;
  customStyle?: Record<string, unknown>;
};

type StatusData = {
  text: string;
  icon: React.ReactElement;
  color: string;
  tooltip: string;
};

const useStatus = ({ status }: ChargerPricing) => {
  return React.useMemo<StatusData>(() => {
    const typeLabel = 'Charger Price';

    switch (status) {
      case TariffSyncStatus.Active:
        return {
          text: 'Active',
          icon: <CheckIcon />,
          color: 'rgb(46, 125, 50)',
          tooltip: `${typeLabel} is active`,
        };
      case TariffSyncStatus.NotSynced:
        return {
          text: 'Not Synced',
          icon: <ErrorOutlineIcon />,
          color: 'rgb(211, 47, 47)',
          tooltip: `Prices failed to sync. We are aware of the issue and working to resolve it. Please check back later.`,
        };
      case TariffSyncStatus.Draft:
        return {
          text: 'Draft',
          icon: <EditOutlinedIcon />,
          color: 'rgb(117, 117, 117)',
          tooltip: `This ${typeLabel} is still in draft mode. Enter the missing information in order to make this ${typeLabel} active.`,
        };
      default:
        return {
          text: '',
          icon: <ErrorOutlineIcon />,
          color: 'inherit',
          tooltip: '',
        };
    }
  }, [status]);
};

export default function TariffForExtendSyncStatus({ data, customStyle }: TariffForExtendSyncStatusProps) {
  const { text, icon, color, tooltip } = useStatus(data);

  return (
    <Tooltip title={tooltip}>
      <Chip
        variant="outlined"
        icon={React.cloneElement(icon, { style: { color: color } })}
        label={text}
        style={{ color: color, borderColor: color, ...customStyle }}
        size="small"
      />
    </Tooltip>
  );
}
