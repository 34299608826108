import { Button, Tab } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TariffCustomPricing } from 'src/@types';
import { Card, CardHeader } from 'src/components/shared/Card';
import { FormAlert } from 'src/components/shared/FormAlert';
import { PageActions } from 'src/components/shared/PageActions';
import { StyledTabs } from 'src/components/shared/StyledTabs';
import { theme } from '@evgo/react-material-components';
import { ReservationFeeInput } from 'src/components/shared/ReservationFeeInput';
import { PriceForm } from './PriceForm';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

enum ChargerPricingFormTab {
  Pricing = 'pricing',
  ReservationFees = 'reservation-fees',
}

type Props = {
  id?: string;
  tariffName?: string;
};

export type FormValues = {
  chargerGroupAltId: string;
  customPricing?: Array<TariffCustomPricing>;
};

export function ChargerPricingForm({ id = '', tariffName }: Props) {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { altId } = useParams<{ altId: string }>();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));
  const location = useLocation();
  const [tab, setTab] = useState(
    location.hash.includes('reservation-fees') ? ChargerPricingFormTab.ReservationFees : ChargerPricingFormTab.Pricing,
  );

  const { isValid, submitCount } = useFormikContext<FormValues>();

  return (
    <Form id={`${id}-custom-price-form`}>
      <StyledTabs
        tab={tab}
        handleChangeTab={(e, newTab) => {
          if (Boolean(location.hash)) {
            navigate(`/extend-plus/charger-prices/${altId}/pricing${isNew ? '?new=true' : ''}`, { replace: true });
            setTab(newTab as ChargerPricingFormTab);
          } else setTab(newTab as ChargerPricingFormTab);
        }}
      >
        <Tab
          label="Charger Prices"
          value={ChargerPricingFormTab.Pricing}
          data-testid={`${id}-charger-prices-tab`}
          onClick={() => track(events.chargerPricePricingDetailsPage.CLICKED_CHARGER_PRICES_TAB)}
        ></Tab>
        <Tab
          label="Reservation Fees"
          value={ChargerPricingFormTab.ReservationFees}
          data-testid={`${id}-reservation-fees-tab`}
          onClick={() => track(events.chargerPricePricingDetailsPage.CLICKED_RESERVATIONS_TAB)}
        ></Tab>
      </StyledTabs>
      {tab === ChargerPricingFormTab.Pricing && <PriceForm tariffName={tariffName} id={'price-form'} />}
      {tab === ChargerPricingFormTab.ReservationFees && (
        <Card mb={4} style={{ marginTop: `${theme.spacing(4)}px` }}>
          <CardHeader
            title="Charger Reservation Fees"
            subtitle="Only chargers that are marked as reservable will have these fees applied. This can be done on the Charger Detail page of a specific charger."
          />
          <ReservationFeeInput name="reservationTerm" id={`${id}-reservation-term-input`} />
        </Card>
      )}
      <PageActions>
        {submitCount > 0 && !isValid && <FormAlert />}
        <Button
          color="secondary"
          size="large"
          onClick={() => navigate(`/extend-plus/charger-prices/${altId}/${isNew ? 'edit?new=true' : 'details'}`)}
          data-testid={`${id}-custom-price-back-btn`}
        >
          Back
        </Button>
        <Button
          type="submit"
          color="secondary"
          size="large"
          variant="contained"
          data-testid={`${id}-custom-price-${isNew ? 'next' : 'save'}-btn`}
        >
          {isNew ? 'Next' : 'Save'}
        </Button>
      </PageActions>
    </Form>
  );
}
