/* eslint-disable @typescript-eslint/no-explicit-any */
import { signInWithEmailAndPassword } from 'src/firebase';

type LoginInput = {
  password: string;
  username: string;
};

type MutationLoginArgs = {
  input: LoginInput;
};

export const login = {
  Mutation: {
    login: async (parent: any, { input }: MutationLoginArgs): Promise<void> => {
      try {
        const { username, password } = input;
        await signInWithEmailAndPassword(username, password);
      } catch (err) {
        throw new Error('Invalid login.');
      }
    },
  },
};
