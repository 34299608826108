import styled from 'styled-components';
import { useMemo } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { useGetSessionsConsumptionForExtend } from 'src/lib/hooks/useGetSessionsConsumptionForExtend';
import { SessionAggregation } from 'src/@types';
import { Card, CardHeader } from 'src/components/shared/Card';
import { BarChart } from 'src/components/shared/UtilizationBarGraph';
import { getGraphData } from './helpers';
import { dateRange } from '../helpers';

type TotalSessionEnergyGraphProps = {
  timePeriod: number;
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

export const TotalSessionEnergyGraph = ({ timePeriod }: TotalSessionEnergyGraphProps) => {
  const input = {
    aggregateBy: SessionAggregation.Day,
    ...dateRange(timePeriod),
  };

  const { data, loading } = useGetSessionsConsumptionForExtend({ input });

  // this is a slight perf optimization as data changes when time period changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const graphData = useMemo(() => getGraphData(data, timePeriod), [data]);

  return (
    <StyledCard data-testid="total-energy-dispensed">
      <CardHeader title="Total Energy Dispensed" subtitle="Total kWh dispensed across all your sites" />

      {loading ? (
        <Box display="flex" justifyContent="center" data-testid="total-session-energy-graph-loading">
          <CircularProgress />
        </Box>
      ) : (
        <BarChart
          key={`total-session-energy-graph-${timePeriod}`}
          id="total-session-energy-graph"
          data-testid="total-session-energy-graph"
          className="total-session-energy-graph"
          captionText=""
          data={graphData}
          color={'#3F657C'}
          height={400}
          valueFormat={(v) => `${Number(v).toLocaleString()}kWh`}
        />
      )}
    </StyledCard>
  );
};
