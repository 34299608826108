import styled from 'styled-components';
import { Dispatch, useMemo } from 'react';
import { theme } from '@evgo/react-material-components';
import { Autorenew as AutorenewIcon } from '@material-ui/icons';
import { addMonths, endOfDay, isAfter } from 'date-fns';
import { Box, Button } from '@material-ui/core';

import { Card, CardHeader } from 'src/components/shared/Card';
import { DateRangeField } from 'src/components/shared/DateRangeField';
import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

import { staticRanges } from './utils';
import { Action, State } from '../filterHelpers';
import { TransactionFilter } from '../../shared/TransactionFilter';
const { spacing } = theme;

export type ReportFilterProps = {
  state: State;
  dispatch: Dispatch<Action>;
};

const StyledDateRangeField = styled(DateRangeField)`
  margin-left: ${spacing(2)}px;
  [class*='MuiFormControl-root'] {
    min-width: 230px;
  }
`;

export function ReportFilter({ state, dispatch }: ReportFilterProps) {
  const { track } = useAnalytics();
  const maxDate = useMemo(() => {
    const today = endOfDay(new Date());
    const max = addMonths(state.filter.dateRange.startDate, 6);
    return isAfter(max, today) ? today : max;
  }, [state.filter.dateRange.startDate]);

  const DateFilter = (
    <StyledDateRangeField
      id="date-filter"
      placeholder="Select date range"
      value={state.filter.dateRange}
      onChange={(dateRange) => dispatch({ type: 'setDateRange', dateRange })}
      DateRangePickerProps={{
        inputRanges: [],
        staticRanges: staticRanges,
        maxDate,
      }}
    />
  );

  return (
    <Card sx={{ pb: 3, my: 4 }} data-testid="report-filters">
      <CardHeader title="Report Filters" />
      <Box display="flex" alignItems="flex-start">
        <Box pt={1} pr={1}>
          {DateFilter}
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            id="sites"
            label="Sites"
            value={state.filter.siteIds}
            options={state.initial.sites.map(({ altId, siteName }) => ({
              value: altId || '',
              label: siteName || '',
            }))}
            onChange={(value) => {
              track(events.reportsView.FILTER_SITE);
              dispatch({ type: 'setSiteIds', siteIds: value });
            }}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            id="prices"
            label="Charger Price Group"
            value={state.filter.chargerGroupIds}
            options={state.initial.chargerGroupPrices?.map(({ altId, chargerGroupName }) => ({
              value: altId || '',
              label: chargerGroupName || '',
            }))}
            onChange={(value) => {
              track(events.reportsView.FILTER_CHARGER_PRICE);
              dispatch({ type: 'setChargerGroupIds', chargerGroupIds: value });
            }}
          />
        </Box>
        <Box pt={1} pl={2}>
          <MultiSelectSearchFilter
            id="plans"
            label="Custom Plan"
            value={state.filter.planIds}
            options={state.initial.chargerGroupPlans.map(({ planId, chargerGroupName }) => ({
              value: planId || '',
              label: chargerGroupName || '',
            }))}
            onChange={(value) => {
              track(events.reportsView.FILTER_CUSTOM_PLAN_PRICE);
              dispatch({ type: 'setPlanIds', planIds: value });
            }}
          />
        </Box>
        <Box pl={2}>
          <TransactionFilter
            value={state.filter.transactionTypes}
            onChange={(value: string[]) => {
              track(events.reportsView.FILTER_TRANSACTION_TYPE);
              dispatch({ type: 'setTransactionTypes', transactionTypeIds: value });
            }}
          />
        </Box>
        <Box pt={2} pl={2}>
          <Button
            role="button"
            data-testid="reset-filters-button"
            color="secondary"
            onClick={() => {
              track(events.reportsView.CLICKED_RESET_FILTERS);
              dispatch({ type: 'resetFilter' });
            }}
            startIcon={<AutorenewIcon />}
          >
            Reset filters
          </Button>
        </Box>
      </Box>
    </Card>
  );
}
