import { TextInfo } from '@evgo/react-material-components';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Button, Typography } from '@material-ui/core';

export const initialValues = {
  email: '',
};

export const ResetPasswordForm: React.FC = () => {
  const className = 'reset-password-form';
  const { handleBlur, handleChange, values, errors, touched } = useFormikContext<Record<string, unknown>>();

  return (
    <Form className={className} data-testid={className}>
      <div>
        <Typography component="p">
          Enter the email address associated with your account and we’ll send you an email to reset your password.
        </Typography>
      </div>
      <TextInfo
        className={clsx(className)}
        label="Email *"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={_.get(values, 'email', '')}
        data-testid="email-input"
        error={errors.email && touched.email}
        helpertext={errors.email && touched.email && errors.email}
      />
      <div className={`${className} actions`}>
        <Button className={className} color="secondary" size="large" variant="contained" type="submit">
          Continue
        </Button>
      </div>
    </Form>
  );
};
