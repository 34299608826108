import React from 'react';
import { ChargerPricing } from 'src/@types';
import { listTariffPriceLog } from 'src/apollo/queries/extendPlus/listTariffPriceLog';
import { Column, Direction, Table } from 'src/components/shared/Table';
import { TableLink } from 'src/components/shared/TableLink';
import config from 'src/config';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { formatPrice } from '../../Details/helpers';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';

type ColumnDataProps = {
  data: ChargerPricing;
};

const ChargerGroupName: React.FC<ColumnDataProps> = ({ data }) => {
  const { track } = useAnalytics();
  const to = `/extend-plus/charger-prices/${data?.altId}/details`;
  return (
    <TableLink to={to} onClick={() => track(events.chargerPricesList.CLICKED_CHARGER_PRICE_LINK)}>
      {data.tariffName}
    </TableLink>
  );
};

const DefaultPricing: React.FC<ColumnDataProps> = ({ data }: { data: Partial<ChargerPricing> }) => {
  const { pricing } = data?.defaultPricing || {};
  const perKwh = pricing?.perKwh || pricing?.perKwh === 0 ? formatPrice(pricing?.perKwh) : null;
  const perMinute = pricing?.perMinute || pricing?.perMinute === 0 ? formatPrice(pricing?.perMinute) : null;
  const perSession = pricing?.perSession || pricing?.perSession === 0 ? formatPrice(pricing?.perSession) : null;
  return (
    <span>
      {perKwh && (
        <>
          ${perKwh} per kWh <br />
        </>
      )}
      {perMinute && (
        <>
          ${perMinute} per min
          <br />
        </>
      )}
      {perSession && <>${perSession} per session</>}
    </span>
  );
};

const PrevPrice: React.FC<ColumnDataProps> = ({ data }) => {
  const previousPrice = data?.eventLog?.edges?.[0]?.jsonData?.defaultPricing?.before || {};
  const perKwh =
    previousPrice?.perKwh || previousPrice?.perKwh === 0 ? formatPrice(parseFloat(previousPrice?.perKwh)) : null;
  const perMinute =
    previousPrice?.perMinute || previousPrice?.perMinute === 0
      ? formatPrice(parseFloat(previousPrice?.perMinute))
      : null;
  const perSession =
    previousPrice?.perSession || previousPrice?.perSession === 0
      ? formatPrice(parseFloat(previousPrice?.perSession))
      : null;

  if (previousPrice) {
    return (
      <span>
        {perKwh && (
          <>
            ${perKwh} per kWh <br />
          </>
        )}
        {perMinute && (
          <>
            ${perMinute} per min
            <br />
          </>
        )}
        {perSession && <>${perSession} per session</>}
      </span>
    );
  } else {
    return <span>-</span>;
  }
};

const UserName: React.FC<ColumnDataProps> = ({ data }) => {
  const user = data?.eventLog?.edges?.[0]?.user?.username;
  return <span>{user ? user : '-'}</span>;
};

const LastUpdate: React.FC<ColumnDataProps> = ({ data }) => {
  const eventDate = data?.eventLog?.edges?.[0]?.eventDate;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get the siteLocalTime of the site of the first charger in the first charger group
  return <span>{eventDate ? moment(eventDate).tz(timeZone).format('MM/DD/YYYY h:mm A z') : '-'}</span>;
};

const columns: Column[] = [
  { key: 'tariffName', label: 'Name', sortable: true, width: '20%', component: ChargerGroupName },
  { key: 'defaultPrice', label: 'Default Price', sortable: false, width: '15%', component: DefaultPricing },
  {
    key: 'eventLog.jsonData',
    label: 'Previous Price',
    numeric: true,
    sortable: false,
    width: '15%',
    component: PrevPrice,
  },
  { key: 'user', label: 'Set / Changed By', sortable: false, width: '15%', component: UserName },
  {
    key: 'eventLog.eventDate',
    label: 'Date / Time',
    sortable: false,
    numeric: false,
    width: '15%',
    component: LastUpdate,
  },
];

export const PriceLog: React.FC = () => {
  const { data, loading, error } = useQuery(listTariffPriceLog, {
    variables: {
      input: {
        pageSize: config.maxPageSize,
      },
      eventLogInput: {
        filter: {
          entityType: {
            eq: 'Charger Price',
          },
          eventTypeId: {
            in: [624, 621],
          },
        },
        pageSize: 1,
        sort: 'EVENT_DATE_DESC',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const edges = data?.listTariffs?.edges || [];
  const formattedData = edges.map((tariff: ChargerPricing) => {
    const chargerGroupCount = tariff.chargerGroups?.length || 0;
    let chargerCount = 0;
    tariff.chargerGroups?.forEach((group) => {
      chargerCount += group?.chargers?.total || 0;
    });
    return { ...tariff, chargerCount, chargerGroupCount };
  });

  return (
    <>
      <Table
        id="charger-groups-table"
        data-testid="charger-groups-table"
        columns={columns}
        data={formattedData}
        loading={loading}
        error={error}
        sorting={{
          sortBy: 'tariffName',
          sortDirection: Direction.Asc,
        }}
        noDataMessage="Create a price to easily apply prices to your chargers"
      />
    </>
  );
};
