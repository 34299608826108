import { GetChargerGroupForExtendQuery } from 'src/@types';
import { transformTariffCustomPricingForExtend } from '../transformTariffPricingForExtend';

type PlanTariffs = NonNullable<GetChargerGroupForExtendQuery['getChargerGroup']>['planTariffs'];

export default function transformPlanTariffForExtend(planTariffs: PlanTariffs) {
  return (planTariffs || []).map((planTariff) => ({
    altId: planTariff?.altId,
    chargerGroupIds: planTariff?.chargerGroups?.map((cg) => cg.altId) || [],
    customPricing: planTariff?.customPricing?.map((cp) => transformTariffCustomPricingForExtend(cp)) || [],
  }));
}
