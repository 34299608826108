import { gql } from '@apollo/client';
import { TariffForExtend } from './fragments';

export const listChargerGroupsTariffs = gql`
  query listChargerGroupsTariffsForExtend($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        altId
        tariff {
          ...TariffForExtend
        }
      }
    }
  }
  ${TariffForExtend}
`;
