import { gql } from '@apollo/client';
import { ChargerGroupForExtend, ChargersWithMeta, PlanTariffForExtend, TariffForExtend } from './fragments';

export const updateChargerPriceGroupForExtend = gql`
  mutation updateChargerPriceGroupForExtend($input: UpdateChargerGroupInput!) {
    updateChargerGroup(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtend}
  ${TariffForExtend}
  ${PlanTariffForExtend}
  ${ChargersWithMeta}
`;
