import { gql } from '@apollo/client';
import { TariffForExtend } from './fragments/Tariff';

export const updateTariffForExtend = gql`
  mutation updateTariffForExtend($input: UpdateTariffInput!) {
    updateTariff(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtend}
`;
