import { Formik, FormikHelpers } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  useCreatePlanTariffsForExtendMutation,
  useGetChargerGroupForExtendQuery,
  useUpdatePlanTariffsForExtendMutation,
} from 'src/@types';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { CustomLoaderContainer } from 'src/components/shared/CustomLoader';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';
import { errorMessageHandler, Handlers } from 'src/lib/helpers/errorMessageHandler';
import transformPlanTariffForExtend from 'src/lib/helpers/transformPlanTariffForExtend';
import { useSnackbar } from 'src/lib/hooks';
import { CustomPlanPriceStepper, Step } from '../CustomPlanPriceStepper';
import { fieldName, newPricingGroup, PricingForm, TariffCustomPricingFormValues } from './PricingForm';
import { validationSchema } from './validation';

export const CustomPlansPricingView: React.FC = () => {
  const id = 'custom-plan-pricing-view';
  const snackbar = useSnackbar();
  const { altId = '' } = useParams();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const isNew = Boolean(queryParams.get('new'));

  const [createPlanTariff, { loading: loadingCreate }] = useCreatePlanTariffsForExtendMutation();
  const [updatePlanTariffs, { loading: loadingUpdate }] = useUpdatePlanTariffsForExtendMutation();

  const loading = loadingCreate || loadingUpdate;

  const variables = { input: { altId, showDeleted: true } };
  const { data } = useGetChargerGroupForExtendQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    skip: false,
  });

  const planTariffs = data?.getChargerGroup?.planTariffs || [];

  const initialValues: TariffCustomPricingFormValues = {
    [fieldName]: planTariffs.length ? transformPlanTariffForExtend(planTariffs) : [newPricingGroup()],
  };

  const chargerGroupId = initialValues[fieldName]?.filter((planTariff) => Boolean(planTariff.altId)).length
    ? altId
    : undefined;

  const handleSubmit = (
    formValues: TariffCustomPricingFormValues,
    { resetForm, setValues }: FormikHelpers<TariffCustomPricingFormValues>,
  ) => {
    const { planTariffs: tariffs = [] } = formValues;

    const values = {
      chargerGroupId: altId || '',
      planTariffs: tariffs?.map((tariff) => ({
        altId: tariff.altId,
        chargerGroupIds: tariff.chargerGroupIds,
        customPricing: tariff.customPricing.map(({ tariffSchedules, pricing }) => {
          return {
            pricing: {
              perKwh: typeof pricing.perKwh === 'boolean' ? null : Number(pricing.perKwh),
              perMinute: typeof pricing.perMinute === 'boolean' ? null : Number(pricing.perMinute),
              perSession: typeof pricing.perSession === 'boolean' ? null : Number(pricing.perSession),
            },
            tariffSchedules: tariffSchedules,
          };
        }),
      })),
    };

    const handleMutation = !chargerGroupId ? createPlanTariff : updatePlanTariffs;
    handleMutation({
      variables: { input: values },
      // @ts-ignore: difficult to type string interpolation on an enum type
      onCompleted: ({ [`${!chargerGroupId ? 'create' : 'update'}PlanTariffsForExtend`]: customPlan }) => {
        navigate(
          `/extend-plus/custom-plans/${isNew ? `${customPlan.altId}/details?new=true` : `${customPlan.altId}/details`}`,
        );
      },
      onError: (error) => {
        const handlers: Handlers[] = [
          {
            codes: ['TARIFF_SCHEDULE_DAY_LIMIT', 'TARIFF_SCHEDULE_WEEK_LIMIT'],
            message: 'You currently have too many price schedules. Try decreasing and try again.',
          },
        ];
        snackbar.error(
          errorMessageHandler({
            error,
            handlers,
            defaultMessage: `Sorry, we were unable to ${!chargerGroupId ? 'create' : 'update'} your Custom Plan Price`,
          }),
        );
        resetForm();
        setValues(formValues);
      },
    });
  };

  return (
    <PageContent
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-custom-plan-prices-list-link`} to="/extend-plus/custom-plans">
              Custom Plan Prices
            </BreadcrumbLink>
            {data?.getChargerGroup?.chargerGroupName && (
              <Breadcrumbs>
                <BreadcrumbLink
                  data-testid={`${id}-custom-plan-details-link`}
                  to={`/extend-plus/custom-plans/${altId}/details`}
                >
                  {data.getChargerGroup.chargerGroupName}
                </BreadcrumbLink>
                <BreadcrumbText>Plan Details</BreadcrumbText>
              </Breadcrumbs>
            )}
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Title>Custom Plan Details</Title>
            </>
          }
        />
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <CustomLoaderContainer
          variations={[
            'Creating pricing for your custom plan...',
            'Ensuring all charger groups are updated, may take a while...',
            'Still updating ☕️...',
          ]}
          interval={5}
          loading={loading}
        >
          {isNew && <CustomPlanPriceStepper currentStep={Step.Pricing} />}
          <PricingForm id="custom-plan-pricing" />
        </CustomLoaderContainer>
      </Formik>
    </PageContent>
  );
};
