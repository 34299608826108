import { useListChargersV2ConnectorsQuery } from 'src/@types';
import { ArrowForward, OfflineBolt } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ConnectorStatusId } from 'src/@types/shared';
import { BuildCircleIcon } from 'src/components/svgs';
import { MetricCard, MetricCardPlaceholder } from 'src/components/shared/MetricCard';
import { LabelDiv } from '../styles';
import config from 'src/config';
import { useMemo } from 'react';

const testid = (value: string) => ({ 'data-testid': `metric-card-connector-status-${value}` });

export const ConnectorStatusMetrics = () => {
  const { data, loading } = useListChargersV2ConnectorsQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
      },
    },
    pollInterval: 5000,
  });

  const connectors = useMemo(() => {
    const chargers = data?.listChargersV2?.edges || [];
    return chargers.flatMap((charger) => charger?.evses?.flatMap((evse) => evse?.connectors));
  }, [data]);

  const offlineConnectorCount = useMemo(
    () => connectors.filter((connector) => connector?.connectorStatus?.columnText === 'Unavailable').length,
    [connectors],
  );
  const inUseConnectorCount = useMemo(
    () => connectors.filter((connector) => connector?.connectorStatus?.columnText === 'Charging').length,
    [connectors],
  );

  const OfflineMetricLabel = offlineConnectorCount ? (
    <Link to={`/extend-plus/chargers?statusFilter=${ConnectorStatusId.Unavailable}`}>
      <LabelDiv>
        <span>VIEW LIST OF CHARGERS</span>
        <ArrowForward />
      </LabelDiv>
    </Link>
  ) : null;

  if (loading)
    return (
      <>
        <MetricCardPlaceholder {...testid('charging-loading')} />
        <MetricCardPlaceholder {...testid('offline-loading')} />
      </>
    );

  return (
    <>
      <MetricCard
        icon={<OfflineBolt />}
        primaryText={inUseConnectorCount || 0}
        secondaryText="In Use"
        label="Currently Charging"
        {...testid('charging')}
      />

      <MetricCard
        icon={<BuildCircleIcon />}
        primaryText={offlineConnectorCount || 0}
        secondaryText="Offline"
        label={OfflineMetricLabel}
        {...testid('offline')}
      />
    </>
  );
};
