import { gql } from '@apollo/client';

export const getChargerGroupPlansForExtend = gql`
  query getChargerGroupPlansForExtend($input: GetChargerGroupInput!) {
    getChargerGroup(input: $input) {
      planTariffTags {
        planChargerGroup {
          altId
          chargerGroupName
          chargerGroupType
          chargerGroupTypeId
          description
          createdAt
          deletedAt
          planDuration
          planId
          status
        }
      }
    }
  }
`;
