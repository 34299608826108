import { gql } from '@apollo/client';
import { ChargerGroupForExtend } from './ChargerGroupForExtend';

export const TariffPricingForExtend = gql`
  fragment TariffPricingForExtend on TariffPricing {
    displayRank
    displayName
    displayDescription1
    displayDescription2
    pricing {
      perKwh
      perMinute
      perSession
    }
  }
`;

export const TariffItemReservationTermForExtend = gql`
  fragment TariffItemReservationTermForExtend on TariffItemReservationTerm {
    fee
    noShowFee
  }
`;

export const TariffScheduleForExtend = gql`
  fragment TariffScheduleForExtend on TariffSchedule {
    daysOfWeek
    endTime
    startTime
  }
`;

export const TariffCustomPricingForExtend = gql`
  fragment TariffCustomPricingForExtend on TariffCustomPricing {
    altId
    displayRank
    displayName
    displayDescription1
    displayDescription2
    pricing {
      perKwh
      perMinute
      perSession
    }
    tariffSchedules {
      ...TariffScheduleForExtend
    }
  }
  ${TariffScheduleForExtend}
`;

export const TariffForExtend = gql`
  fragment TariffForExtend on ChargerPricing {
    id
    altId
    tariffDescription
    tariffDisplayName
    tariffName
    tariffRank
    status
    chargerGroups {
      ...ChargerGroupForExtend
      chargers: chargersLoaded {
        total
      }
    }
    defaultPricing {
      ...TariffPricingForExtend
    }
    customPricing {
      ...TariffCustomPricingForExtend
    }
    reservationTerm {
      ...TariffItemReservationTermForExtend
    }
  }
  ${ChargerGroupForExtend}
  ${TariffPricingForExtend}
  ${TariffCustomPricingForExtend}
  ${TariffItemReservationTermForExtend}
`;
