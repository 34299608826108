import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetChargerGroupForExtendQuery } from 'src/@types';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { ChargerGroupTabs } from './ChargerGroupDetailsTabs';
import styled from 'styled-components';
import { theme } from '@evgo/react-material-components';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  button {
    margin-top: -${theme.spacing(0.5)}px;
    margin-left: ${theme.spacing()}px;
  }
`;

export const ChargerGroupDetailsView: React.FC = () => {
  const id = 'charger-group-details';
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { altId = '' } = useParams<{ altId: string }>();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  const variables = {
    input: { altId },
  };

  const { data } = useGetChargerGroupForExtendQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const chargerGroup = data?.getChargerGroup;

  return (
    <PageContent
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-charger-groups-link`} to="/extend-plus/charger-groups">
              Charger Groups
            </BreadcrumbLink>
            <BreadcrumbText data-testid={`${id}-charger-group-breadcrumb`}>
              {chargerGroup?.chargerGroupName}
            </BreadcrumbText>
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <StyledTitle>
                <Title data-testid={`${id}-title`}>{chargerGroup?.chargerGroupName}</Title>
                <IconButton
                  data-testid="edit-link-btn"
                  onClick={() => {
                    navigate(`/extend-plus/charger-groups/${chargerGroup?.altId}/edit`);
                    track(events.chargerGroupDetailsPage.CLICKED_CHARGER_GROUP_HEADER_EDIT);
                  }}
                >
                  <Edit />
                </IconButton>
              </StyledTitle>
              <SubTitle color="textPrimary">{chargerGroup?.description}</SubTitle>
            </>
          }
        />
      }
    >
      <div>
        <ChargerGroupTabs testId={id} data={chargerGroup} />
      </div>
    </PageContent>
  );
};
