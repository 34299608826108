import moment from 'moment';
import { CSVLink } from 'react-csv';
import { GetApp } from '@material-ui/icons';
import { useRef } from 'react';
import { Button, CircularProgress, Box } from '@material-ui/core';

import config from 'src/config';
import { useSnackbar } from 'src/lib/hooks';
import { EventLog, ListEventLogFilterInput, Maybe } from 'src/@types';
import { listChargerPricesEventLog } from 'src/apollo/queries/extendPlus';
import { useLazyQuery } from '@apollo/client';
import { formatDate } from 'src/lib/helpers/formatDate';
import { formatTime } from 'src/lib/helpers/formatTime';
import { formatPrice } from '../../ChargerPrices/Details/helpers';
import { useFormattedEventForExport } from 'src/lib/hooks/useChargerPriceEventsForExport';
import { formatTimeZoneAbbreviation, userLocalTimeZone } from 'src/lib/helpers/formatTimeZoneAbbreviation';

type ExportButtonProps = {
  empty: boolean;
  filter: ListEventLogFilterInput;
  disabled?: boolean;
  pageSize?: number;
  sort?: string;
};

const formatChargerPriceEventLogValue = (pricing: { perKwh: number; perMinute: number; perSession: number }) => {
  const perKwh =
    Number(pricing?.perKwh) || Number(pricing?.perKwh) === 0 ? `$${formatPrice(Number(pricing?.perKwh))} per kWh` : '';
  const perMinute =
    Number(pricing?.perMinute) || Number(pricing?.perMinute) === 0
      ? `$${formatPrice(Number(pricing?.perMinute))} per minute`
      : '';
  const perSession =
    Number(pricing?.perSession) || Number(pricing?.perSession) === 0
      ? `$${formatPrice(Number(pricing?.perSession))} per session`
      : '';
  return `${perKwh} ${perMinute} ${perSession}`;
};

const getPreviousValue = (edge: Maybe<EventLog>) => {
  if (edge?.jsonData?.defaultPricing) {
    return formatChargerPriceEventLogValue(edge?.jsonData?.defaultPricing?.before);
  }
  return edge?.jsonData?.chargerGroups?.before
    ? (edge?.jsonData?.chargerGroups?.before || []).join(', ')
    : edge?.jsonData?.tariffName?.before;
};
const getNewValue = (edge: Maybe<EventLog>) => {
  if (edge?.jsonData?.defaultPricing) {
    return formatChargerPriceEventLogValue(edge?.jsonData?.defaultPricing?.after);
  }
  return edge?.jsonData?.chargerGroups?.after
    ? (edge?.jsonData?.chargerGroups?.after || []).join(', ')
    : edge?.jsonData?.tariffName?.after;
};

export type FormattedEventForExport = {
  'Charger Price': string | null | undefined;
  'Event Date (Local)': string | null | undefined;
  'Event Date (UTC)': string | null | undefined;
  'Event Type': string | null | undefined;
  'Event Details': string | null | undefined;
  'New Value': string | null | undefined;
  'Previous Value': string | null | undefined;
  User: string | null | undefined;
  'Event Data': string | null | undefined;
};
const timeZone = userLocalTimeZone;
export const formatEventForExport = (edge: Maybe<EventLog>): FormattedEventForExport => {
  return {
    'Charger Price': edge?.entityData?.tariffName,
    'Event Date (Local)': `${formatDate(edge?.eventDate, 'L', timeZone)} ${formatTime(
      edge?.eventDate,
      'LT',
      timeZone,
    )} ${formatTimeZoneAbbreviation()}`,
    'Event Date (UTC)': `${formatDate(edge?.eventDate)} ${formatTime(edge?.eventDate)} UTC`,
    'Event Type': edge?.eventType?.columnText,
    'Event Details': edge?.eventText,
    'New Value': getNewValue(edge),
    'Previous Value': getPreviousValue(edge),
    User: edge?.user?.username,
    'Event Data': `${JSON.stringify(edge?.jsonData).replaceAll('"', '""')}`,
  };
};

export default function ExportButton({ empty, disabled, filter, pageSize, sort }: ExportButtonProps) {
  const ref = useRef<CSVLink>();
  const snackbar = useSnackbar();
  const today = moment(new Date()).format('YYYY-MM-DD');
  const filename = `Reports_${today}_event_log.csv`;

  const [runQuery, { loading, data }] = useLazyQuery(listChargerPricesEventLog);

  const onClick = async () => {
    if (empty) {
      snackbar.error("There's no data to export.");
      return;
    }

    await runQuery({
      variables: {
        input: {
          page: 0,
          pageSize: pageSize || config.maxPageSize,
          filter,
          sort,
        },
      },
    });

    // @ts-ignore
    ref.current?.link?.click();
  };

  const rows = useFormattedEventForExport({ events: data?.listEventLog?.edges || [] });

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={onClick}
        disabled={loading || disabled}
        data-testid="download-charger-prices-event-log-button"
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : <GetApp />}

        <Box component="span" pl={2}>
          Download CSV
        </Box>
      </Button>

      <CSVLink ref={ref as never} data={rows} filename={filename} />
    </>
  );
}
