import { gql } from '@apollo/client';
import { PlanTariffTag } from './fragments';

export const listChargerGroupsPlanTariffTags = gql`
  query listChargerGroupsPlanTariffTagsForExtend($input: ListChargerGroupsInput!) {
    listChargerGroups(input: $input) {
      edges {
        altId
        chargerGroupName
        planTariffTags {
          ...PlanTariffTag
        }
      }
    }
  }
  ${PlanTariffTag}
`;
