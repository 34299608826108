/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModalContext } from 'src/contexts/ModalContext';
import { useSnackbarContext } from 'src/contexts/SnackBarContext';
import { SnackbarType } from 'src/@types/shared';

type GetPath = { subject: string; pathname: string; data: any };

export type TransformErrorOptions = {
  error?: any;
  setFieldError?: any;
};

export type UseFormikSubmitOptions = {
  id?: string;
  altId?: string;
  callback?: any;
  callbackArgs?: any;
  initialValues?: any;
  noRedirect?: any;
  omitFields?: any;
  statusFields?: any;
  transformResponse?: any;
  transformValues?: any;
  transformError?: (transformErrorOptions: TransformErrorOptions) => string;
  confirmationDialogCallback?: boolean;
  snackVisible?: boolean;
  snackSuccessMessage?: string;
  snackErrorMessage?: string;
  inputKey?: string;
  redirectTo?: string;
  noFormReset?: boolean;
};

const getCreateAltId = (subject: string) => {
  switch (subject) {
    case 'Model':
      return 'createChargerModel.altId';
    case 'AccountOwner':
      return 'account.altId';
    default:
      return `create${subject}.altId`;
  }
};

const getPath = ({ subject, pathname, data }: GetPath) => {
  const createAltId = getCreateAltId(subject);
  switch (subject) {
    case 'Login':
      return '/';
    case 'AccountOwner':
      return `/${pathname.split('/')[1]}/${_.get(data, createAltId)}/profile`;
    case 'Tag':
      return `/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${_.get(data, createAltId, 'list')}`;
    case 'ChargerPriceGroupForExtend':
      return `/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${_.get(data, createAltId)}/chargers?new=true`;
    case 'ChargerGroupPlanForExtend':
      return `/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${_.get(data, createAltId)}/pricing?new=true`;
    default:
      return `/${pathname.split('/')[1]}/${_.get(data, createAltId, 'list')}`;
  }
};

/**
 * A hook to generate a function to pass to Formik's onSubmit
 */
export const useFormikSubmit = (
  subject: string,
  createMutation: any,
  updateMutation: any,
  options: UseFormikSubmitOptions = {},
): any => {
  if (!subject) throw new Error('Invalid useFormikSubmit argument: Missing subject');
  if (!createMutation) throw new Error('Invalid useFormikSubmit argument: Missing createMutation');
  if (!updateMutation) throw new Error('Invalid useFormikSubmit argument: Missing updateMutation');

  const initialValues = useMemo(() => options.initialValues || {}, [options.initialValues]);
  const callback = options.callback || null;
  const callbackArgs = options.callbackArgs || '';
  const confirmationDialogCallback = options.confirmationDialogCallback || false;
  const omitFields = useMemo(() => options.omitFields || [], [options.omitFields]);
  const statusFields = useMemo(() => options.statusFields || [], [options.statusFields]);
  const transformResponse = useMemo(
    () => options.transformResponse || ((data: any) => data),
    [options.transformResponse],
  );
  const transformError = useMemo(() => options.transformError || null, [options.transformError]);
  const transformValues = useMemo(
    () =>
      options.transformValues ||
      ((values: any, altId: string, id: string) => ({
        ..._.omit(values, omitFields),
        ...(altId ? { altId } : {}),
        ...(id ? { id } : {}),
      })),
    [omitFields, options.transformValues],
  );
  const { altId, id } = options;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [handleCreate] = useMutation(createMutation);
  const [handleUpdate] = useMutation(updateMutation);
  const { setSnackbarState } = useSnackbarContext();
  const { setModalState } = useModalContext();

  return useCallback(
    async (values: any, { resetForm, setFieldError, setSubmitting }: any) => {
      const input = transformValues(values, altId, id);
      const willUpdate = !!altId || !!id;
      let handleMutate = handleCreate;
      let snackMessage = `${subject} Created`;
      if (subject === 'Login') {
        snackMessage = 'Login succeeded';
      } else if (subject === 'AccountSync') {
        snackMessage = 'Successfully updated FalconDB with latest data from Driivz';
      } else if (['ChargerPrice', 'ChargerPriceGroupForExtend'].includes(subject)) {
        snackMessage = 'Your charger prices have been saved.';
      } else if (subject === 'ExtendUser') {
        snackMessage = 'User has been assigned to host';
      }

      let snackbarType = 'success';
      if (willUpdate) {
        handleMutate = handleUpdate;
        snackMessage = `${subject} Updated`;
      }

      if (subject === 'setPlanTariffs') {
        snackMessage = 'Your Custom Plan Price has been saved';
      }

      let inputName = _.toLower(subject);
      if (subject === 'Vehicle') inputName = 'accountVehicle';
      if (subject === 'AccountOwner' && willUpdate) inputName = 'account';

      const inputKey =
        options.inputKey || subject === 'Card' || (!willUpdate && (subject === 'Driver' || subject === 'AccountOwner'))
          ? 'input'
          : `${inputName}Input`;

      try {
        const variables = subject === 'AccountSync' ? { ...input } : { [inputKey]: input };
        if (subject === 'Driver' || subject === 'Account' || (subject === 'AccountOwner' && willUpdate))
          variables.preferencesInput = { pageSize: 999 };
        const { data } = await handleMutate({ variables });

        setSubmitting(false);
        if (willUpdate) {
          const status =
            subject === 'Model'
              ? transformResponse(_.get(data, 'updateChargerModel', {}))
              : transformResponse(_.get(data, `update${subject}`, {}));
          if (!options.noFormReset) {
            resetForm({ status, values: _.omit(status, statusFields) });
          }
        } else if (!options.noRedirect) {
          const to =
            options.redirectTo ||
            getPath({
              subject,
              pathname,
              data: subject === 'AccountOwner' ? _.get(data, 'createAccountOwner', {}) : data,
            });

          navigate(to, {
            replace: true,
          });
        } else {
          resetForm({ status: initialValues, values: initialValues });
        }
      } catch (error: any) {
        if (error.message !== "Cannot read property 'data' of undefined") {
          snackMessage = error.message || 'A problem has occurred. Please try again.';
          snackbarType = 'error';

          if (transformError) {
            snackMessage = transformError({ error, setFieldError });
          } else {
            _.forEach(_.get(error, 'graphQLErrors', []), (graphQLError) => {
              const fields = _.get(graphQLError, 'fields', _.get(graphQLError, 'extensions.exception.fields', []));

              if (!_.isEmpty(fields)) {
                _.forEach(fields, ({ message, field }) => {
                  snackMessage = 'Please correct the errors and try again.';
                  setFieldError(_.camelCase(field), message);
                });
              }
              _.forEach(_.get(graphQLError, 'errorMessage', []), ({ message, path }) => {
                snackMessage = 'Please correct the errors and try again.';
                setFieldError(_.camelCase(path), message);
              });
            });
          }

          setSubmitting(false);
          setSnackbarState({
            snackMessage: options.snackErrorMessage || snackMessage,
            snackVisible: true,
            snackbarType: snackbarType as SnackbarType,
          });
        }
        setSubmitting(false);
      }

      if (callback) {
        try {
          await callback(callbackArgs);
        } catch (error) {
          console.error('Formik Sumbit Callback Error:', error); // eslint-disable-line no-console
        }
      }
      if (!confirmationDialogCallback) {
        setModalState({
          modalVisible: false,
          modalName: '',
          modalProps: {},
        });
        return setSnackbarState({
          snackMessage: options.snackErrorMessage || snackMessage,
          snackVisible: subject === 'Login' && snackbarType === 'success' ? false : true,
          snackbarType: snackbarType as SnackbarType,
        });
      }
    },
    [
      altId,
      callback,
      callbackArgs,
      handleCreate,
      handleUpdate,
      setModalState,
      setSnackbarState,
      initialValues,
      id,
      pathname,
      navigate,
      statusFields,
      subject,
      transformError,
      transformResponse,
      transformValues,
      options,
      confirmationDialogCallback,
    ],
  );
};
