import { gql } from '@apollo/client';

export const ChargerGroupForExtend = gql`
  fragment ChargerGroupForExtend on ChargerGroup {
    altId
    chargerGroupName
    chargerGroupType
    chargerGroupTypeId
    description
    createdAt
    deletedAt
    planDuration
    planId
    status
  }
`;
