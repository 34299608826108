import { gql } from '@apollo/client';
import { ChargerGroupForExtend } from './ChargerGroupForExtend';
import { TariffCustomPricingForExtend } from './Tariff';

export const PlanTariffForExtend = gql`
  fragment PlanTariffForExtend on PlanTariff {
    altId
    tariffName
    tariffDescription
    chargerGroups {
      ...ChargerGroupForExtend
    }
    customPricing {
      ...TariffCustomPricingForExtend
    }
  }
  ${ChargerGroupForExtend}
  ${TariffCustomPricingForExtend}
`;
