/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextInfo } from '@evgo/react-material-components';
import { Button } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import useAutofilled from '../../../../../lib/hooks/useAutofilled';

export interface Props {
  className?: string;
}

export interface FormValues {
  username: string;
  password: string;
}

export const LoginForm: React.FC<Props> = ({ className }) => {
  const { handleChange, handleBlur, handleReset, touched, errors, values } = useFormikContext<FormValues>();
  const isAutoFilled = useAutofilled({ fieldName: 'username' });

  const isDisabled = useCallback(() => {
    const hasErrors = !_.isEmpty(errors);
    const hasValues = !!(values.username && values.password);
    const enabledByAutofill = isAutoFilled && _.isEmpty(touched) && !hasValues;
    return (hasErrors || !hasValues) && !enabledByAutofill;
  }, [errors, values, touched, isAutoFilled]);

  return (
    <Form className={className}>
      <TextInfo
        className={className}
        label="Email Address"
        name="username"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.username}
        error={errors.username && touched.username}
        helpertext={errors.username && touched.username && errors.username}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextInfo
          className={className}
          label="Password"
          name="password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={errors.password && touched.password}
          helpertext={errors.password && touched.password && errors.password}
        />
        <Button href={'/resetPassword'} color="secondary" size="small" data-testid="forgot-password-button">
          Forgot Password?
        </Button>
      </div>
      <div className={`${className} actions`}>
        <Button
          id="cancel"
          className={className}
          color="primary"
          onClick={() => handleReset()}
          size="large"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          className={className}
          color="secondary"
          size="large"
          variant="contained"
          type="submit"
          disabled={isDisabled()}
        >
          SIGN IN
        </Button>
      </div>
    </Form>
  );
};
