import { gql } from '@apollo/client';

export const listChargerPricesEventLog = gql`
  query listChargerPricesEventLog($input: ListEventLogInput!) {
    listEventLog(input: $input) {
      total
      edges {
        user {
          username
        }
        eventText
        eventDate
        entityType
        eventTypeId
        jsonData
        entityId
        eventType {
          id
          columnName
          columnValue
          columnText
        }
        entityData {
          ... on ChargerPricing {
            tariffName
          }
        }
      }
    }
  }
`;
