/* stylelint-disable color-no-hex */
import { theme } from '@evgo/react-material-components';
import styled from 'styled-components';

const { spacing } = theme;

export const Styled = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: ${`0 calc(${spacing(6)}px + var(--safe-area-inset-right)) 0 calc(${spacing(
    6,
  )}px + var(--safe-area-inset-left))`};
  padding-bottom: ${spacing(9)}px;

  header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${spacing(8)}px;

    > div {
      width: 33%;
      margin: 0 auto ${spacing(3)}px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: ${spacing(2)}px;
    }
  }

  .snackbar {
    position: absolute;
    z-index: 2000;
    align-self: center;

    span {
      display: flex;
      align-items: center;
      font-weight: 200;

      svg {
        margin-right: ${spacing()}px;
      }
    }

    button {
      color: #ffffff;
    }

    &.error {
      background-color: #cb2e29;
    }

    &.success {
      background-color: #43a047;
    }
  }
`;
