import moment from 'moment-timezone';
import { useState } from 'react';
import { Paper, Box } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { PriceCardContent } from '../PriceCardContent';
import { TariffSchedule, TariffCustomPrices } from 'src/@types';
import { weekDays, mininumCardWidth, mininumCardHeight, cardMargin, rowHeightPixels } from '../helpers';

export type PriceCardProps = {
  name: string;
  color: string;
  bgcolor: string;
  tariffSchedules: TariffSchedule;
  pricing: TariffCustomPrices;
};

export const PriceCard = ({ name, color, bgcolor, tariffSchedules, pricing }: PriceCardProps) => {
  const [hover, setHover] = useState(false);
  const startTimeNumber = moment.duration(tariffSchedules.startTime).asHours();
  const endTimeIsForNextDay = tariffSchedules.endTime.startsWith('00:00');
  const endTimeNumber = endTimeIsForNextDay ? 24 : moment.duration(tariffSchedules.endTime).asHours();
  const startDay = tariffSchedules.daysOfWeek[0];
  const endDay = tariffSchedules.daysOfWeek[tariffSchedules.daysOfWeek.length - 1];
  const startDayNumber = weekDays.indexOf(startDay);
  const endDayNumber = weekDays.indexOf(endDay);
  const cardWidth = (endDayNumber - startDayNumber + 1) * 12.5;
  const cardHeight = (endTimeNumber - startTimeNumber) * rowHeightPixels - cardMargin;
  const contentWillFit = cardWidth > mininumCardWidth && cardHeight > theme.spacing(mininumCardHeight);

  return (
    <Box
      data-testid={`card-pricing-group-${startDayNumber}-${startTimeNumber.toString().replace('.', '-')}`}
      position="absolute"
      bgcolor={bgcolor}
      boxShadow={`2px 2px 4px 0px ${bgcolor}`}
      component={Paper}
      left={`${startDayNumber * 12.5 + 12.5}%`}
      top={`${startTimeNumber * rowHeightPixels + rowHeightPixels}px`}
      height={`${cardHeight}px`}
      width={`calc(${cardWidth}% - ${cardMargin * 2}px)`}
      marginX={`${cardMargin}px`}
      marginY={`${cardMargin / 2}px`}
      borderRadius={`${theme.spacing(1)}px`}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <PriceCardContent {...{ name, color, startDay, endDay, pricing, ...tariffSchedules }} />
      {hover && !contentWillFit && (
        <Box
          data-testid={`card-pricing-group-${startDayNumber}-${startTimeNumber.toString().replace('.', '-')}-hover`}
          position="absolute"
          zIndex={1}
          minWidth={`${theme.spacing(mininumCardWidth)}px`}
          height={`${theme.spacing(mininumCardHeight)}px`}
          right={`-${theme.spacing(mininumCardWidth)}px`}
          top={`-${theme.spacing(mininumCardHeight)}px`}
          component={Paper}
          borderRadius={`${theme.spacing(1)}px`}
        >
          <PriceCardContent
            {...{
              name,
              color: theme.palette.grey['900'],
              startDay,
              endDay,
              pricing,
              ...tariffSchedules,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
